import styled from 'styled-components';
import arrow from '../../assets/icons/arrow.svg';

const Select = styled.select`
    margin-top: 10px;
    width: 100%;
    padding: 12.5px 40px 12.5px 12.5px;
    border: solid 1.5px ${props => props?.errors ? "var(--color-required)" : "var(--color-border)"};
    border-radius: 10px;
    color: var(--color-grey);
    font-size: var(--fs-text);
    font-weight: var(--fw-regular);
    font-family: inherit;
    background: transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(${arrow});
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;

    :focus {
        outline: none;
        border: solid 1.5px var(--color-green)
    }
`;

export default Select