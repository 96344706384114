import { useFormData } from "../../contexts/FormContext";
import styled from "styled-components";
import RequiredText from "../RequiredText/RequiredText";
import radio from "../../assets/icons/radio.svg";
import HiddenField from './HiddenField';
import Input from '../Input/Input';

const StyledTextField = styled(Input)`
    display: block;
    margin: 13px 0px 0px 35px;
    width: calc(100% - 35px);
`

const Wrapper = styled.div`
    margin-top: 12px;
    display: flex;
    ${({$other}) => $other && 'flex-wrap: wrap'};
`

const RadioInput = styled.input`
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 100%;
    vertical-align: middle;
    border: 1.5px solid #DCDCDC;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0 13px 0 0;

    &:checked {
        border: 0;
        background-image: url(${radio});
    }
`

const RadioLabel = styled.label`
    vertical-align: middle;
    font-size: var(--fs-text);
    color: var(--color-grey);
    font-weight: var(--fw-regular);
`


const RadioField = ({children, ...props}) => {
    const formReply = useFormData();

    const isChecked = (questionId, optionId) => {
        return formReply.values[questionId] === optionId;
    }

    const getOther = (questionId, optionId) => {
        if (formReply.values[questionId] === optionId) {
           return formReply.values[`other-${optionId}`]
        } else {
            return "";
        }
    }

    return (
        <>
            <HiddenField/>
            {props.options.map((option) => {
                return (
                    <Wrapper key={option.id} $other={option.type === 'other'}>
                        <RadioInput type='radio' id={option.id} name={props.name} value={option.id} defaultChecked={isChecked(props.name, option.id)}
                           {...props.register(`${props.name}.0`, {
                               required: props.required,
                               onChange: (e) => {props.fieldBlur()},
                           })}
                        />
                        <RadioLabel htmlFor={option.id}>{option.title}</RadioLabel>
                        {option.type === 'other' && props.value.includes(option.id) &&
                            <StyledTextField type="text" defaultValue={getOther(props.name, option.id)}
                                 {...props.register(`other-${option.id}`, {
                                     onBlur: (e) => {props.fieldBlur()},
                                 })}
                            />
                        }
                    </Wrapper>
                )
            })}
            {props.errors && <RequiredText>Dit veld is verplicht</RequiredText>}
        </>
    )
}

export default RadioField