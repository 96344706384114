import {useLoaderData} from 'react-router-dom';
import {Container} from '../../components/Utils/Utils';
import {PageTitle} from '../../components/Heading/Heading';
import Button from '../../components/Button/Button';
import styled from 'styled-components';
import {RichTextHolder} from '../../components/Text/Text';

const StyledContainer = styled(Container)`
    padding-top: 200px;

    @media screen and (max-width: 768px) {
        padding-top: 120px;
    }
`;

const StyledGuestHome = styled.div`
    min-height: calc(100vh - 250px);
    display: flex;
    
    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

const Content = styled.div`
    flex: 1;
`;

const Image = styled.div`
    flex: 1;
    
    img {
        width: 100%;
    }
`;

const StyledRichTextHolder = styled(RichTextHolder)`
    margin-bottom: 18px;
`;

export default function GuestHome() {
    const {data} = useLoaderData();

    return (
        <StyledContainer>
            <StyledGuestHome>
                <Content>
                    <PageTitle>{data.title}</PageTitle>
                    <StyledRichTextHolder dangerouslySetInnerHTML={{__html: data.description}}/>
                    <Button to={"/quick-scan"}>{data.buttonText}</Button>
                </Content>
                <Image>
                    <img src={data.imageURL} alt="" />
                </Image>
            </StyledGuestHome>
        </StyledContainer>
    );
}