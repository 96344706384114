import styled from 'styled-components';
import RequiredText from '../RequiredText/RequiredText';
import Select from '../Select/Select';

const StyledSelectField = styled(Select)``;

const SelectField = ({children, ...props}) => {
    return (
        <>
            <StyledSelectField defaultValue={props.value[0]}
                {...props.register(`${props.name}.0`, {
                    required: props.required,
                    onBlur: (e) => {props.fieldBlur()},
                })}
                errors={props.errors}
            >
                <option value=""></option>
                {props.options.map((option) => {
                    return (
                        <option key={option.id} value={option.id}>{option.title}</option>
                    )
                })}
            </StyledSelectField>
            {props.errors && <RequiredText>Dit veld is verplicht</RequiredText>}
        </>
    )
}

export default SelectField