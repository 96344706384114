import {Page, WrappedContainer} from '../../components/Utils/Utils';
import {Heading2} from '../../components/Heading/Heading';
import styled from 'styled-components';
import {Text} from '../../components/Text/Text';
import Button from '../../components/Button/Button';
import {useForm} from 'react-hook-form';
import React, {useEffect, useRef, useState} from 'react';
import Question from '../../components/Question/Question';
import {Link, redirect, useActionData, useNavigation, useSubmit} from 'react-router-dom';
import Cookies from 'js-cookie';

const Outer = styled.div`
    padding: 0 20px;
    
    @media screen and (max-width: 580px) {
        padding: 0;
    }
`;

const Inner = styled.article`
    border-radius: 20px;
    padding: 40px;
    box-shadow: var(--bs-form);
    background-color: var(--color-white);
    display: flex;

    @media screen and (max-width: 768px) {
        padding: 30px 20px;
    }

    @media screen and (max-width: 580px) {
        border-radius: 0;
        flex-direction: column;
    }
`;

const LoginBox = styled.div`
    flex: 1 1 0;
    border-right: 1px solid rgba(var(--rgb-color-grey), .1);
    padding-right: 40px;

    ${Heading2} {margin-bottom: 30px; }
    
    & form > div:nth-of-type(1) {
        margin-bottom: 20px;
    }

    & form > div:nth-of-type(2) {
        margin-bottom: 8px;
    }

    @media screen and (max-width: 580px) {
        padding-right: 0;
        border-right: none;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(var(--rgb-color-grey), .1);

        ${Heading2} {margin-bottom: 10px; }
    }
`;

const SignUpBox = styled.div`
    flex: 1 1 0;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    
    ${Heading2} { margin-bottom: 30px; }
    ${Text} { margin: 0 0 30px 0; }

    @media screen and (max-width: 580px) {
        margin-left: 0;
        padding-top: 20px;

        ${Heading2} {margin-bottom: 10px; }
    }
`;

const ResetPassword = styled.div`
    text-align: right;
    margin-bottom: 30px;

    a {
        text-decoration: none;
        color: var(--color-green);
        font-weight: var(--fw-bold);

        &:hover {
            color: var(--link-hover);
        }
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
`;

export async function loginAction({request}) {
    let formData = await request.formData();

    const body = {
        "email": formData.get("email"),
        "password":  formData.get("password"),
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login`, {
            signal: request.signal,
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if(!response.ok) {
            throw new Response("Something went wrong", { status: response.status });
        }

        const data = await response.json();
        Cookies.set('kern_token', data.accessToken, {secure: true});
        return redirect('/');
    } catch(error) {
        return { "error": error };
    }
}

export default function Login() {
    const {register, handleSubmit, setError, formState: {errors}} = useForm();
    const [status, setStatus] = useState('idle');
    const actionData = useActionData();
    const navigation = useNavigation();
    const submit = useSubmit();
    let prevState = useRef('idle');

    useEffect(() => {
        if((prevState.current === 'loading' && navigation.state === 'idle') && actionData?.error) {
            setStatus('idle');
            setError('email', { type: 'required', message: 'De ingevulde gegevens komen niet overeen'})
        }

        prevState.current = navigation.state;
    }, [actionData, navigation.state, setError])

    const onSubmit = async values => {
        setStatus('submitting');
        submit(values, { method: "post", action: "/inloggen" });
    }

    return (
        <Page $withBackground>
            <WrappedContainer $noPadding>
                <Outer>
                    <Inner>
                        <LoginBox>
                            <Heading2>Inloggen</Heading2>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Question
                                    key="email"
                                    field={{id: "email", title: "E-mailadres", type: "email", required: 1}}
                                    placeholder="E-mailadres"
                                    register={register}
                                    errors={errors["email"]}
                                    lessMarginOnMobile={true}
                                    hideRequired={true}
                                />

                                <Question
                                    key="password"
                                    field={{id: "password", title: "Wachtwoord", type: "password", required: 1}}
                                    placeholder="Wachtwoord"
                                    register={register}
                                    errors={errors["password"]}
                                    lessMarginOnMobile={true}
                                    hideRequired={true}
                                />

                                <ResetPassword><Link to="/wachtwoord-resetten">Wachtwoord resetten</Link></ResetPassword>

                                <ButtonWrapper>
                                    <Button as="button" disabled={status === "submitting"} loading={status === "submitting"} type="submit">Log in</Button>
                                </ButtonWrapper>
                            </form>
                        </LoginBox>
                        <SignUpBox>
                            <Heading2>Aanmelden</Heading2>
                            <Text>Heb je nog geen account? Voltooi dan eerst de Quick Scan. Met het invullen van de scan krijg je inzicht in jouw logistieke processen en op basis daarvan ontvang je gepersonaliseerd advies.</Text>

                            <ButtonWrapper>
                                <Button to={"/quick-scan"}>Start de Quick Scan</Button>
                            </ButtonWrapper>
                        </SignUpBox>
                    </Inner>
                </Outer>
            </WrappedContainer>
        </Page>
    );
}