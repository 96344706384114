import styled from 'styled-components';
import {Link, useLoaderData} from 'react-router-dom';
import React from 'react';
import {HiMail, HiPhone} from 'react-icons/hi';
import {Heading3} from '../Heading/Heading';
import {useQuickScanLink} from '../../hooks/useQuickScanLink';

const StyledFooter = styled.footer`
    background-color: var(--color-dark-green);
    padding: 50px 0;
`;

const FooterContainer = styled.div`
    margin: 0 auto;
    max-width: 858px;
    padding: 0 20px
`;

const Columns = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    article:last-of-type { text-align: right; }
    
    @media screen and (max-width: 858px) {
        grid-template-columns: repeat(2, 1fr);
        
        article:last-of-type { text-align: left; }
        article:not(:last-of-type) { padding-bottom: 40px; }
    }
    
    @media screen and (max-width: 525px) {
        grid-template-columns: 1fr;
    }
`;

const StyledHeading3 = styled(Heading3)`
    font-size: var(--fs-footer-heading);
    margin: 0 0 15px;
`;

const RichText = styled.div`
    p {
        color: var(--color-white);
        margin: 0;
        line-height: 1.35;
        font-size: var(--fs-text);
    }
`;

const List = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

const ListItem = styled.li`

    svg {
        color: var(--color-white);
        vertical-align: middle;
        margin-right: 15px;
    }
    
    a {
        color: var(--color-white);
        font-size: var(--fs-text);
        word-break: break-all;
    }
    
    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
`;

const Logo = styled.img`
    width: 178px;
    height: 90px;
    
    @media screen and (max-width: 220px) {
        width: 100%;
    }
`;

const StyledCopyright = styled.section`
    background: rgba(var(--rgb-dark-green), .7);
    font-size: var(--fs-copyright);
    padding: 10px 0;
`;

const Notice = styled.p`
    color: var(--color-white);
    font-size: var(--fs-copyright);
    text-align: center;
    margin: 0;
    
    a { color: inherit; }
`;

const StyledLink = styled(Link)`
    cursor: pointer;
    text-decoration: underline;
`;

const Footer = () => {
    const {checkLoginData, data} = useLoaderData();
    const isLoggedIn = Boolean(checkLoginData?.isLoggedIn);
    const { isInQuickScan, handleLink } = useQuickScanLink();

    return (
        <footer>
            <StyledFooter>
                <FooterContainer>
                    <Columns>
                        <article>
                            <StyledHeading3 $white>{data.titleBlock1}</StyledHeading3>
                            <RichText dangerouslySetInnerHTML={{__html: data.textBlock1}} />
                        </article>
                        <article>
                            <StyledHeading3 $white>{data.titleBlock2}</StyledHeading3>
                            <List>
                                <ListItem><HiMail size={'1.25em'} /> <a href={`mailto:${data.email}`}>{data.email}</a></ListItem>
                                <ListItem><HiPhone size={'1.25em'} /> <a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a></ListItem>
                            </List>
                        </article>
                        <article>
                            <StyledLink
                                as={isInQuickScan ? "div" : undefined}
                                to={isInQuickScan ? undefined : '/'}
                                onClick={isInQuickScan ? () => handleLink('/', isLoggedIn) : undefined}
                            >
                                <Logo src={data.footerLogoURL} alt='Het logo van KERN Duurzame Logistiek' aria-label="Home" title="Home" />
                            </StyledLink>
                        </article>
                    </Columns>
                </FooterContainer>
            </StyledFooter>
            <StyledCopyright>
                <FooterContainer>
                    <Notice>KERN Logistiek Makelaar {new Date().getFullYear()} | <StyledLink as={isInQuickScan ? "span" : undefined} to={isInQuickScan ? undefined : '/privacy-policy'} onClick={isInQuickScan ? () => handleLink('/privacy-policy', isLoggedIn) : undefined}>Privacybeleid</StyledLink> | <StyledLink as={isInQuickScan ? "span" : undefined} to={isInQuickScan ? undefined : '/cookiebeleid'} onClick={isInQuickScan ? () => handleLink('/cookiebeleid', isLoggedIn) : undefined}>Cookiebeleid</StyledLink></Notice>
                </FooterContainer>
            </StyledCopyright>
        </footer>
    )
}

export default Footer