import styled from 'styled-components';

export const HorizontalImageOuter = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    
    @supports not (aspect-ratio: 16 / 9) {
        padding-top: 56.25%; /* 9/16*100 */
    }
`;

export const HorizontalImageInner = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @supports not (aspect-ratio: 16 / 9) {
        position: absolute;
        top: 0;
    }
`;

export const HorizontalImage = ({src, alt, ...props}) => {
    return (
        <HorizontalImageOuter {...props}>
            <HorizontalImageInner src={src} alt={alt} />
        </HorizontalImageOuter>
    )
}