import styled from "styled-components";
import TextField from '../FormFields/TextField';
import TextAreaField from '../FormFields/TextAreaField';
import EmailField from '../FormFields/EmailField';
import NumberField from '../FormFields/NumberField';
import CheckBoxField from '../FormFields/CheckBoxField';
import RadioField from '../FormFields/RadioField';
import SelectField from '../FormFields/SelectField';
import DateField from '../FormFields/DateField';
import TimeField from '../FormFields/TimeField';
import StarField from '../FormFields/StarField';
import RankingField from '../FormFields/RankingField';
import Label from '../Label/Label';
import PasswordField from '../FormFields/PasswordField';
import SupplierField from "../FormFields/SupplierField";

const StyledAsterisk = styled.span`
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-text);
    color: var(--color-asterisk);
`;

const StyledQuestion = styled.div`
    margin-bottom: 35px;
    
    ${({$lessMarginOnMobile}) => $lessMarginOnMobile && `
        @media screen and (max-width: 768px) {
            margin-bottom: 25px;
        }
    `}
`;

const Question = (props) => {
    let field = props.field;
    let fieldKey = field.id;

    let fieldElement = ((fieldType) => {
        switch (fieldType) {
            case "text":
                return <TextField
                    name={fieldKey}
                    readOnly={field.readOnly}
                    placeholder={props.placeholder}
                    value={props.value}
                    required={field.required}
                    errors={props.errors}
                    register={props.register}
                    fieldBlur={props.fieldBlur} />
            case "textarea":
                return <TextAreaField
                    name={fieldKey}
                    placeholder={props.placeholder}
                    value={props.value}
                    required={field.required}
                    errors={props.errors}
                    register={props.register}
                    fieldBlur={props.fieldBlur} />
            case "email":
                return <EmailField
                    name={fieldKey}
                    readOnly={field.readOnly}
                    placeholder={props.placeholder}
                    value={props.value}
                    required={field.required}
                    errors={props.errors}
                    register={props.register}
                    fieldBlur={props.fieldBlur}/>
            case "number":
                return <NumberField
                    name={fieldKey}
                    value={props.value}
                    required={field.required}
                    errors={props.errors}
                    register={props.register}
                    fieldBlur={props.fieldBlur} />
            case "checkbox":
                return <CheckBoxField
                    options={field.options}
                    name={fieldKey}
                    value={props.value}
                    required={field.required}
                    errors={props.errors}
                    register={props.register}
                    fieldBlur={props.fieldBlur} />
            case "radio":
                return <RadioField
                    options={field.options}
                    name={fieldKey}
                    value={props.value}
                    required={field.required}
                    errors={props.errors}
                    register={props.register}
                    fieldBlur={props.fieldBlur} />
            case "select":
                return <SelectField
                    options={field.options}
                    name={fieldKey}
                    value={props.value}
                    required={field.required}
                    errors={props.errors}
                    register={props.register}
                    fieldBlur={props.fieldBlur} />
            case "date":
                return <DateField
                    name={fieldKey}
                    value={props.value}
                    required={field.required}
                    errors={props.errors}
                    register={props.register}
                    fieldBlur={props.fieldBlur} />
            case "time":
                return <TimeField
                    name={fieldKey}
                    value={props.value}
                    required={field.required}
                    errors={props.errors}
                    register={props.register}
                    fieldBlur={props.fieldBlur} />
            case "star":
                return <StarField
                    name={fieldKey}
                    value={props.value}
                    register={props.register}
                    fieldBlur={props.fieldBlur} />
            case "ranking":
                return <RankingField
                    options={field.options}
                    name={fieldKey}
                    required={field.required}
                    errors={props.errors}
                    register={props.register}
                    unregister={props.unregister}
                    fieldBlur={props.fieldBlur} />
            case "supplier":
                return <SupplierField
                    name={fieldKey}
                    value={props.value}
                    setValue={props.setValue}
                    required={field.required}
                    errors={props.errors}
                    register={props.register}
                    fieldBlur={props.fieldBlur} />
            case "password":
                return <PasswordField
                    name={fieldKey}
                    placeholder={props.placeholder}
                    value={props.value}
                    required={field.required}
                    errors={props.errors}
                    register={props.register}
                    fieldBlur={props.fieldBlur}
                    watch={props.watch} />
            default:
                return null;
        }
    })(field.type);

    let labelElement = <Label htmlFor={fieldKey}>{field.title}</Label>;
    let isRequiredMark = <StyledAsterisk>{(field.required && !props.hideRequired) ? `\u00a0*` : ''}</StyledAsterisk>;

    return (
        <StyledQuestion $lessMarginOnMobile={props.lessMarginOnMobile}>
            {labelElement}
            {isRequiredMark}
            {fieldElement}
        </StyledQuestion>
    )
}

export default Question;