import {useEffect} from 'react';
import {useFormData} from '../../contexts/FormContext';
import {Heading2} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {useOutletContext} from 'react-router-dom';

export default function FormSuccess() {
    const {isLoggedIn} = useOutletContext();
    const {clearFormContext} = useFormData()

    useEffect(() => {
        if(!isLoggedIn) clearFormContext();
    // eslint-disable-next-line
    }, [])

    return (
            <>
                <Heading2>{isLoggedIn ? "Jouw Quick Scan is gewijzigd!" : "Gefeliciteerd!"}</Heading2>
                <Text>{isLoggedIn ? "De wijzigingen van jouw Quick Scan zijn succesvol opgeslagen!" : "Je hebt je stadslogistiek profiel aangemaakt, hiermee krijg je toegang tot relevante informatie uit de regio, inzicht in voorbeelden hoe andere bedrijven het aanpakken en tools die voor jou kunnen werken richting efficiënt en duurzaam goederenvervoer."}</Text>
            </>
    );
}