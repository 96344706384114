import styled from 'styled-components';
import Button from '../Button/Button';
import {Text} from '../Text/Text';
import Cookies from 'js-cookie';
import {useState} from 'react';
import {Link} from 'react-router-dom';

const StyledCookieConsent = styled.div`
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    background-color: var(--color-white);
    box-shadow: var(--bs-block);
    border-radius: 10px;
    padding: 10px 10px 10px 15px;
    display: flex;
    align-items: center;
    gap: 20px;
    z-index: 98;
    
    ${Text} { margin: 0; }
    
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        transform: none;
        left: 20px;
        bottom: 20px;
        margin-right: 20px;
    }
`;

const ButtonWrapper = styled.div`
    @media screen and (max-width: 1024px) {
        align-self: flex-end;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: var(--color-green);
    font-weight: var(--fw-bold);

    &:hover {
        color: var(--link-hover);
    }
`;

const CookieConsent = () => {
    const [acceptedCookies, setAcceptedCookies] = useState(Boolean(Cookies.get('kern_cookies')));

    const handleAcceptation = () => {
        Cookies.set('kern_cookies', true, {expires: 365, secure: true});
        setAcceptedCookies(true);
    }

    if(acceptedCookies) return null;

    return (
        <StyledCookieConsent>
            <Text>KERN gebruikt standaard functionele cookies om ervoor te zorgen dat de website werkt zoals verwacht. D.m.v. analytische cookies krijgen we inzicht in hoe u onze website gebruikt en hoe efficiënt onze marketing is. <StyledLink to="/cookiebeleid">Lees meer</StyledLink>.</Text>
            <ButtonWrapper>
                <Button as="button" onClick={handleAcceptation}>Begrepen</Button>
            </ButtonWrapper>
        </StyledCookieConsent>
    );
}

export default CookieConsent;