import styled from 'styled-components';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import {textStyles} from '../../../components/Text/Text';

ChartJS.register(ArcElement, Tooltip, Legend);

const StyledSupplyChainChart = styled.div``;

const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
    
    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 30px;
        margin-top: 30px;
    }
`;

const LegendBox = styled.div`
    flex-basis: 400px;

    @media screen and (max-width: 1024px) {
       flex-basis: unset;
    }
`;

const ChartBox = styled.div`
    flex-basis: 415px;
    max-width: 415px;
    width: 100%;

    @media screen and (max-width: 1024px) {
        flex-basis: unset;
    }
`;

const CustomLegend = styled.div`
    ul {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 0;
        padding: 0;
    }
    
    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        gap: 10px;
        
        > p {
            font-weight: var(--fw-bold);
            flex-shrink: 0;
        }
    }
    
    div {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    
    span {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        flex-shrink: 0;
    }
    
    p {
        ${textStyles};
        margin: 0;
    }
    
    @media screen and (max-width: 375px) {
        li {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;

            > p {
                margin-left: 24px;
            }
        }
    }
`;

export const SupplyChainChart = ({id, emission, labels, dataValues, selectedSupplyChain}) => {
    const options = {
        plugins: {
            htmlLegend: {
                containerID: `legend-container-${id}`,
            },
            legend: {
                display: false,
            },
            tooltip: {
                titleFont: {weight: 'normal'},
                displayColors: false,
                callbacks: {
                    title: function(context) {
                        const currentTitle = context[0].label;
                        return currentTitle.length > 40 ? currentTitle.substring(0, 40) + "..." : currentTitle;
                    },
                    label: function(context) {
                        return context.raw.toLocaleString('nl', {maximumFractionDigits: 2});
                    },
                }
            },
        }
    };

    const data = {
        labels: labels,
        datasets: [
            {
                data: dataValues,
                backgroundColor: [
                    '#709F2D',
                    '#355B59',
                    '#AEBDBD',
                    '#E2ECD5',
                    '#A9C581',
                    '#5D7C7A',
                    '#DBE2E1',
                ],
                borderColor: [
                    '#fff',
                ],
                borderWidth: 3,
            },
        ],
    };

    const getOrCreateLegendList = (chart, id) => {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer.querySelector('ul');

        if (!listContainer) {
            listContainer = document.createElement('ul');
            legendContainer.appendChild(listContainer);
        }

        return listContainer;
    };

    const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate(chart, args, options) {
            const ul = getOrCreateLegendList(chart, options.containerID);

            // Remove old legend items
            while (ul.firstChild) {
                ul.firstChild.remove();
            }

            // Reuse the built-in legendItems generator
            const items = chart.options.plugins.legend.labels.generateLabels(chart);

            items.forEach(item => {
                // Legend item
                const li = document.createElement('li');

                li.onclick = () => {
                    const {type} = chart.config;
                    if (type === 'pie' || type === 'doughnut') {
                        // Pie and doughnut charts only have a single dataset and visibility is per item
                        chart.toggleDataVisibility(item.index);
                    } else {
                        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    }
                    chart.update();
                };

                // Left box
                const div = document.createElement('div');

                // Color span
                const span = document.createElement('span');
                span.style.background = item.fillStyle;
                div.appendChild(span);

                // Label
                const textContainer = document.createElement('p');
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);
                div.appendChild(textContainer);

                // Value
                const valueContainer = document.createElement('p');
                valueContainer.style.textDecoration = item.hidden ? 'line-through' : '';

                const emissionForCurrentSupplyChain = emission?.emissionPerSupplyChain?.find(i => i.supplyChainText === item.text)?.emission;
                const emissionForCurrentSubLabel = emission?.emissionPerSupplyChain?.find(supplyChain => supplyChain.supplyChainID === selectedSupplyChain)?.emissionPerSubLabel?.find(subLabel => subLabel.subLabel === item.text)?.emission;
                const value = document.createTextNode(selectedSupplyChain ? (emissionForCurrentSubLabel !== undefined ? emissionForCurrentSubLabel?.toLocaleString('nl', {maximumFractionDigits: 2}) : "-") : emissionForCurrentSupplyChain?.toLocaleString('nl', {maximumFractionDigits: 2}));
                valueContainer.appendChild(value);

                li.appendChild(div);
                li.appendChild(valueContainer);
                ul.appendChild(li);
            });
        }
    };

    return (
        <StyledSupplyChainChart>
            <Flex>
                <LegendBox>
                    <CustomLegend id={`legend-container-${id}`} />
                </LegendBox>
                <ChartBox>
                    <Pie
                        data={data}
                        options={options}
                        plugins={[htmlLegendPlugin]}
                    />
                </ChartBox>
            </Flex>
        </StyledSupplyChainChart>
    );
}