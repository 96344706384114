import styled, {css} from 'styled-components';
import {truncateMixin} from '../Utils/Utils';

export const pageTitleStyles = css`
    margin: 0;
    font-family: var(--font-mulish);
    font-weight: var(--fw-bold);
    font-size: var(--fs-h1);
    color: var(--color-dark-green);
    ${({ $truncate }) => $truncate && truncateMixin};
    sub { vertical-align: bottom; }
`;

export const PageTitle = styled.h1`
    ${pageTitleStyles};
`;

export const heading2Styles = css`
    font-family: var(--font-mulish);
    font-size: var(--fs-h2);
    color: var(--color-dark-green);
    position: relative;
    margin: 0px 0px 40px 0px;
    padding: 2.5px 0px 2.5px 30px;
    background: url("data:image/svg+xml,%0A%3Csvg width='61' height='50' viewBox='0 0 61 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-8.19058e-07 31.2621L5.63108 40.7767L16.2136 48.4466L24.1748 47.3786L34.5631 50L52.6214 50L53.9806 42.4272L53.9806 33.0097L60.0971 25.9223L56.8932 21.4563L55.6311 17.3786L56.8932 14.3689L47.3786 4.85436L29.6117 2.52033e-06L19.8058 3.49514L12.1359 10.1942L12.1359 13.5922L5.04855 23.3981L-8.19058e-07 31.2621Z' fill='%23709F2D' fill-opacity='0.2'/%3E%3C/svg%3E%0A") no-repeat left top;

    @media screen and (max-width: 768px) {
        margin: 0px 0px 20px 0px;
        background-size: 40px 48px;
        padding: 13.5px 0 13.5px 23px;
    }
`;

export const Heading2 = styled(PageTitle).attrs({
    as: 'h2'
})`
    ${heading2Styles};
`;

export const heading3Styles = css`
    font-family: var(--font-mulish);
    font-size: var(--fs-h3);
    font-weight: var(--fw-semi-bold);
    color: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-dark-green)'};
`;

export const Heading3 = styled(PageTitle).attrs({
    as: 'h3'
})`
    ${heading3Styles};
`;