import Select from 'react-select';
import styled from 'styled-components';
import arrow from '../../assets/icons/arrow.svg';

const SelectWrapper = styled.div`
    margin-top: 4px;

    .kern-select {
        &__value-container {
            font-size: var(--fs-text);
            padding: 8px 40px 8px 14px;
        }
        
        &__input-container { color: var(--color-grey); }
        
        &__placeholder { 
            color: var(--color-grey);
            margin-left: 0;
        }
        
        &__dropdown-indicator {
            display: none;
            transition: unset; 
        }
        
        &__multi-value {
            color: var(--color-white);
            background-color: var(--color-green);
            font-size: var(--fs-multi-select-label);
            
            &__remove {
                cursor: pointer;

                &:hover {
                    background-color: var(--color-green-60);
                }
            }
            
            &__label { 
                color: var(--color-white);
                font-size: unset;
            }
        }
        
        &__indicator-separator { background-color: var(--color-white); }
        
        &__menu {
            font-size: var(--fs-text);
            color: var(--color-grey);
            border-radius: 10px;
            box-shadow: 0 0 0 1px var(--color-supplier-field-dropdown-border);
        }
        
        &__menu-notice--no-options {
            color: var(--color-grey);
        }

        &__option {
            padding: 13px 16px;
        }
    }
`;

// Customize React Select theme colors and some styles
const selectTheme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: 'var(--color-green)',
        primary75: 'var(--color-green)',
        primary50: 'var(--color-supplier-field-accent)',
        primary25: 'var(--color-supplier-field-accent)',
        danger: 'var(--color-white)',
        dangerLight: 'var(--color-white)',
        neutral0: 'var(--color-white)',
        neutral5: 'var(--color-green)',
        neutral10: 'var(--color-green)',
        neutral20: 'var(--color-green)',
        neutral30: 'var(--color-green)',
        neutral40: 'var(--color-green)',
        neutral50: 'var(--color-green)',
        neutral60: 'var(--color-green)',
        neutral70: 'var(--color-green)',
        neutral80: 'var(--color-green)',
        neutral90: 'var(--color-green)',
    }
})

const selectStyle = {
    control: (styles, state) => ({
        ...styles,
        backgroundImage: `url(${arrow})`,
        backgroundRepeat: `no-repeat, repeat`,
        backgroundPosition: `right .85em top 50%, 0 0`,
        border: state.isFocused ? '1.5px solid var(--color-green)' : '1.5px solid var(--color-border)',
        outline: 'none',
        minHeight: '50px',
        boxShadow: 'none',
        transition: 'unset',
        borderRadius: '10px',
        ':hover': {
            border: state.isFocused ? '1.5px solid var(--color-green)' : '1.5px solid var(--color-border)',
        }
    }),
}

export const MultiSelectField = ({data, value, handleOnChange}) => {
    const options = data.map(item => ({
        value: item.id,
        label: item.label
    }));

    return (
        <SelectWrapper>
            <Select
                className="kern-select"
                classNamePrefix="kern-select"
                isMulti
                isClearable={false}
                options={options}
                value={options?.filter(option => value?.includes(option.value))}
                closeMenuOnSelect={false}
                theme={selectTheme}
                styles={selectStyle}
                placeholder="Selecteer"
                noOptionsMessage={() => "Geen opties beschikbaar"}
                onChange={(value, action) => {
                    switch (action.action) {
                        case 'select-option': // Selecting option from list
                            handleOnChange(action.option.value, "add");
                            break;
                        case 'pop-value': // Removing option with backspace
                        case 'remove-value': // Removing option with cross button
                            if(action.removedValue) handleOnChange(action.removedValue.value, "remove");
                            break;
                        default:
                            return undefined
                    }
                }}
            />
        </SelectWrapper>
    );
}