import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Link, NavLink, useFetcher, useLoaderData, useLocation} from 'react-router-dom';
import {useQuickScanLink} from '../../hooks/useQuickScanLink';
import {HiMenu, HiX} from 'react-icons/hi';
import background from "../../assets/background-inverted.svg";

const Header = styled.header`
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    background-color: ${props => props.$scrolled ? 'var(--color-white)' : 'transparent'};
    box-shadow: ${props => props.$scrolled ? 'var(--bs-nav)' : 'none'};
    box-shadow: ${props => props.$scrolled ? 'var(--bs-nav)' : 'none'};
    padding: ${props => props.$scrolled ? '20px 0' : '40px 0'};
    transition: box-shadow .2s ease, background-color .2s ease, padding .2s ease;
`;

const Container = styled.div`
    max-width: 1260px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Logo = styled.img`
    max-width: ${props => props.$scrolled ? '118px' : '178px'};
    width: 100%;
    max-height: ${props => props.$scrolled ? '60px' : '90px'};
    transition: max-width .2s ease, max-height .2s ease;

    @media screen and (max-width: 875px) {
        max-width: ${props => props.$scrolled ? '118px' : '130px'};
        max-height: ${props => props.$scrolled ? '60px' : '65px'};
    }
`;

const Nav = styled.nav`
    flex-shrink: 0;
    
    @media screen and (max-width: 768px) {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        will-change: transform;
        background-color: var(--color-dark-green);
        width: 100%;
        min-height: 100vh;
        height: 100%;

        @supports (min-height: -webkit-fill-available) {
            min-height: -webkit-fill-available;
        }

        max-width: 768px;
        z-index: 100;
        
        &.transition { transition: transform .2s ease; }
        &.is-open { transform: none; }
    }
`;

const RelativeInner = styled.div`
    @media screen and (max-width: 768px) {
        display: none;
        position: relative;
        padding: 160px 20px 80px 20px;
        height: 100%;
        
        &.is-open {
            display: block;
        }
    }
`;

const List = styled.ul`
    list-style-type: none;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 0;
    }
`;

const ListItem = styled.li`
    display: inline-flex;
    
    &:not(:last-of-type) {
        margin-right: 25px;

        @media screen and (max-width: 875px) {
            margin-right: 0;
        }
    }
`;

const StyledLink = styled(Link)`
    cursor: pointer;
`;

const StyledNavLink = styled(NavLink)`
    cursor: pointer;
    color: var(--color-dark-green);
    font-weight: var(--fw-bold);
    font-family: var(--font-mulish);
    font-size: var(--fs-nav);
    text-decoration: none;
    position: relative;
    padding: 12.5px 0 12.5px 19px;
    background: none;
    border: none;

    &:hover{
        color: var(--color-green);
    }

    &.active {
        background: url("data:image/svg+xml,%0A%3Csvg width='61' height='50' viewBox='0 0 61 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-8.19058e-07 31.2621L5.63108 40.7767L16.2136 48.4466L24.1748 47.3786L34.5631 50L52.6214 50L53.9806 42.4272L53.9806 33.0097L60.0971 25.9223L56.8932 21.4563L55.6311 17.3786L56.8932 14.3689L47.3786 4.85436L29.6117 2.52033e-06L19.8058 3.49514L12.1359 10.1942L12.1359 13.5922L5.04855 23.3981L-8.19058e-07 31.2621Z' fill='%23709F2D' fill-opacity='0.2'/%3E%3C/svg%3E%0A") no-repeat left center;

        @media screen and (max-width: 875px) {
            background-size: 40px 48px;
        }
    }

    @media screen and (max-width: 875px) {
        padding: 15px 18px 15px 18px;
    }
    
    @media screen and (max-width: 768px) {
        color: var(--color-white);
        font-size: 20px;
        padding: 15px 18px 15px 18px;

        &:hover {
            color: var(--color-white);
        }
    }
`;

const HamburgerIcon = styled(HiMenu)`
    display: none;
     
    @media screen and (max-width: 768px) {
        display: block;
        color: var(--color-dark-green);
    }
`;

const CloseButton = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        right: 20px;
        top: ${({$scrolled}) => $scrolled ? '36px' : '58px'};
    }
`;

const CloseIcon = styled(HiX)`
    color: var(--color-white);
`;

const DecorationImage = styled.img`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        height: auto;
        object-fit: contain;
        position: absolute;
        ${({$bottomRight}) => $bottomRight ? 'bottom: -120px;' : 'top: -100px;'};
        ${({$bottomRight}) => $bottomRight ? 'right: -220px;' : 'left: -100px;'};
        width: ${({$bottomRight}) => $bottomRight ? '440px' : '260px'};
        transform: rotate(${({$bottomRight}) => $bottomRight ? '0' : '-30deg'});
    }
`;

const Navbar = () => {
    const {checkLoginData, data} = useLoaderData();
    const isLoggedIn = Boolean(checkLoginData?.isLoggedIn);
    const { isInQuickScan, handleLink } = useQuickScanLink();

    // Change navbar style on scroll
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        const listenScroll = () => (window.scrollY > 50) ? setScrolled(true) : setScrolled(false);

        window.addEventListener('scroll', listenScroll)
        return () => window.removeEventListener('scroll', listenScroll)
    }, []);

    // Handle mobile navbar menu
    const menuRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const openMenu = () => {
        menuRef?.current?.classList.add("transition", "is-open");
        document.documentElement.classList.add('no-scroll');
        document.body.classList.add('no-scroll');
        setIsOpen(true);
    };

    const closeMenu = () => {
        menuRef?.current?.classList.add("transition");
        menuRef?.current?.classList.remove("is-open");
        document.documentElement.classList.remove('no-scroll');
        document.body.classList.remove('no-scroll');
        setIsOpen(false);
    };

    const onTransitionEnd = () => menuRef?.current?.classList.remove("transition");

    useEffect(() => {
        let localRef = null;

        if(menuRef.current) {
            localRef = menuRef.current;
            localRef.addEventListener("transitionend", onTransitionEnd);
        }

        return () => {
            if (localRef) localRef.removeEventListener("transitionend", onTransitionEnd);
        };
    }, [isOpen]);

    const {pathname} = useLocation();
    const handleMenuLink = (url) => {
        if (isInQuickScan) handleLink(url, isLoggedIn)
        if (isOpen && (pathname === url)) closeMenu();
    }

    // Close menu if pathname changes
    useEffect(() => {
        if(isOpen) closeMenu();
    // eslint-disable-next-line
    }, [pathname])

    // Handle logout button
    const fetcher = useFetcher();
    const handleLogout = async () => {
        if(isInQuickScan) {
            if (window.confirm(`Je staat op het punt de Quick Scan af te sluiten. Alle ${isLoggedIn ? "gewijzigde en niet opgeslagen" : "ingevulde"} data zal verloren gaan. Weet je zeker dat je de Quick Scan wil ${isLoggedIn ? "afsluiten" : "stoppen"}?`) === true) {
                fetcher.submit({}, {method: 'POST', action: '/uitloggen'});
            }
        } else {
            fetcher.submit({}, {method: 'POST', action: '/uitloggen'});
        }
    }

    return (
        <Header $scrolled={scrolled}>
            <Container>
                <StyledLink
                    as={isInQuickScan ? "div" : undefined}
                    to={isInQuickScan ? undefined : '/'}
                    onClick={() => handleMenuLink('/')}
                >
                    <Logo src={data.headerLogoURL} alt='Het logo van KERN Duurzame Logistiek' aria-label="Home" title="Home" $scrolled={scrolled} />
                </StyledLink>

                <Nav ref={menuRef}>
                    <RelativeInner className={isOpen ? 'is-open' : ''}>
                        <CloseButton $scrolled={scrolled}>
                            <CloseIcon size={'2em'} onClick={closeMenu} />
                        </CloseButton>
                        <DecorationImage src={background} alt="" />
                        <DecorationImage src={background} alt="" $bottomRight />
                        <List>
                            <ListItem>
                                <StyledNavLink
                                    as={isInQuickScan ? "div" : undefined}
                                    to={isInQuickScan ? undefined : '/'}
                                    onClick={() => handleMenuLink('/')}
                                >
                                    Home
                                </StyledNavLink>
                            </ListItem>
                            <ListItem>
                                <StyledNavLink
                                    as={isInQuickScan ? "div" : undefined}
                                    to={isInQuickScan ? undefined : '/over'}
                                    onClick={() => handleMenuLink('/over')}
                                >
                                    Over
                                </StyledNavLink>
                            </ListItem>
                            <ListItem>
                                <StyledNavLink
                                    as={isInQuickScan ? "div" : undefined}
                                    to={isInQuickScan ? undefined : '/quick-scan'}
                                    onClick={() => handleMenuLink('/quick-scan')}
                                    className={isInQuickScan ? 'active' : ''}
                                    reloadDocument={pathname.startsWith("/quick-scan/success")}
                                >
                                    {checkLoginData?.isLoggedIn ? "Mijn Quick Scan" : "Quick Scan"}
                                </StyledNavLink>
                            </ListItem>
                            <ListItem>
                                <StyledNavLink
                                    as={isInQuickScan ? "div" : undefined}
                                    to={isInQuickScan ? undefined : '/contact'}
                                    onClick={() => handleMenuLink('/contact')}
                                >
                                    Contact
                                </StyledNavLink>
                            </ListItem>

                            {checkLoginData?.isLoggedIn ? (
                                <ListItem>
                                    <StyledNavLink
                                        as="button"
                                        onClick={handleLogout}
                                        disabled={fetcher.state !== 'idle'}
                                    >
                                        Uitloggen
                                    </StyledNavLink>
                                </ListItem>
                            ) : (
                                <ListItem>
                                    <StyledNavLink
                                        as={isInQuickScan ? "div" : undefined}
                                        to={isInQuickScan ? undefined : '/inloggen'}
                                        onClick={() => handleMenuLink('/inloggen')}
                                    >
                                        Inloggen
                                    </StyledNavLink>
                                </ListItem>
                            )}
                        </List>
                    </RelativeInner>
                </Nav>

                <HamburgerIcon size={'2em'} onClick={openMenu} />
            </Container>
        </Header>
    )
}

export default Navbar