import styled from 'styled-components';

const StyledLabel = styled.label`
    font-family: var(--font-mulish);
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-label);
    color: var(--color-dark-green);
`;

const Label = ({className, children}) => {
    return (
        <StyledLabel className={className}>
            {children}
        </StyledLabel>
    )
}

export default Label