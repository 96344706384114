import {Container, Page} from '../../components/Utils/Utils';
import Cookies from 'js-cookie';
import {redirect, useLoaderData} from 'react-router-dom';
import {Heading2, Heading3, PageTitle} from '../../components/Heading/Heading';
import styled from 'styled-components';
import {SupplyChainChart} from './Partials/SupplyChainChart';
import {useEffect, useState} from 'react';
import Label from '../../components/Label/Label';
import Select from '../../components/Select/Select';

const Section = styled.article`
    box-shadow: var(--bs-block);
    background-color: var(--color-white);
    border-radius: 20px;
    padding: 50px;
    z-index: 1;
    position: relative;
    margin-top: 60px;

    ${Heading2} {
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        border-radius: 0;
        padding: 20px;
        margin-top: 20px;
    }
`;

const TotalEmission = styled.div`
    flex: 1 1 50%;
`;

const DisplayEmission = styled.h3`
    font-family: var(--font-mulish);
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-h1);
    color: var(--color-dark-green);
    margin: 0;
    overflow-wrap: anywhere;

    @media screen and (max-width: 768px) {
        margin-top: 4px;
    }
`;

const MyFootprintWrapper = styled.div`
    padding: 0 20px;

    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

const Flex = styled.div`
    display: flex;
    gap: 80px;
    margin-top: 50px;
    ${({$addMarginBottom}) => $addMarginBottom && 'margin-bottom: 40px;'};
    
    @media screen and (max-width: 1024px) {
        gap: 40px;
    }
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        margin-top: 10px;
    }
`;

const SelectBox = styled.div`
    flex: 1 1 50%;
`;

export async function myFootprintLoader({request}) {
    const hasAccessToken = Boolean(Cookies.get('kern_token'));
    if(!hasAccessToken) return redirect('/inloggen');

    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/CO2Emission`, {
        headers: {
            Authorization: `Bearer ${Cookies.get('kern_token')}`
        },
        signal: request.signal
    });

    if(!res.ok) {
        // Redirect to login if Unauthorized
        if(res.status === 401) {
            Cookies.remove('kern_token');
            return redirect("/inloggen");
        }

        throw new Response("Page not found", { status: 404 });
    }

    const data = await res.json();

    return {data};
}

export default function MyFootprint() {
    const {data} = useLoaderData();

    // Create labels and dataValues for total emission per supply chain
    let supplyChainLabels = [];
    let supplyChainDataValues = [];

    data.emissionPerSupplyChain.forEach(supplyChain => {
        supplyChainLabels.push(supplyChain.supplyChainText);
        supplyChainDataValues.push(supplyChain.emission);
    });

    // Create labels and dataValues for specific sublabel emission per supply chain
    const [selectedSupplyChain, setSelectedSupplyChain] = useState(data?.emissionPerSupplyChain[0]?.supplyChainID || false);
    const [labels, setLabels] = useState([]);
    const [dataValues, setDataValues] = useState([]);

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedSupplyChain(selectedValue);
    };

    useEffect(() => {
        if (selectedSupplyChain) {
            const selectedSupplyChainData = data.emissionPerSupplyChain.find(supplyChain => supplyChain.supplyChainID === selectedSupplyChain);

            if (selectedSupplyChainData) {
                const selectedLabels = selectedSupplyChainData.emissionPerSubLabel.map(subLabel => subLabel.subLabel);
                const selectedDataValues = selectedSupplyChainData.emissionPerSubLabel.map(subLabel => subLabel.emission);

                setLabels(selectedLabels);
                setDataValues(selectedDataValues);
            }
        }
    }, [data, selectedSupplyChain]);

    return (
        <Page $withBackground>
            <Container>
                <PageTitle>Mijn CO<sub>2</sub> footprint</PageTitle>
            </Container>
            <Container $noPadding>
                <MyFootprintWrapper>
                    <Section>
                        <Heading2>Emissies per goederenstroom</Heading2>

                        <Flex>
                            <TotalEmission>
                                <Heading3>Totale kg CO<sub>2</sub> emissie per week</Heading3>
                                <DisplayEmission>{data.emissionTotal?.toLocaleString('nl', {maximumFractionDigits: 2})}</DisplayEmission>
                            </TotalEmission>
                        </Flex>

                        <SupplyChainChart id="perSupplyChain" emission={data} labels={supplyChainLabels} dataValues={supplyChainDataValues} />
                    </Section>

                    {selectedSupplyChain &&
                        <Section>
                            <Heading2>Bekijk specifieke emissie</Heading2>

                            <Flex $addMarginBottom>
                                <SelectBox>
                                    <Label htmlFor="selectSupplyChain">
                                        Selecteer de goederenstroom
                                    </Label>
                                    <Select id="selectSupplyChain" value={selectedSupplyChain} onChange={handleSelectChange}>
                                        <option value="default" disabled>Selecteer een goederenstroom</option>

                                        {data?.emissionPerSupplyChain?.map(item => (
                                            <option key={item.supplyChainID} value={item.supplyChainID}>{item.supplyChainText}</option>

                                        ))}
                                    </Select>
                                </SelectBox>

                                <TotalEmission>
                                    <Heading3>Totale kg CO<sub>2</sub> emissie van '{data?.emissionPerSupplyChain?.find(i => i.supplyChainID === selectedSupplyChain)?.supplyChainText}'</Heading3>
                                    <DisplayEmission>{data?.emissionPerSupplyChain?.find(i => i.supplyChainID === selectedSupplyChain)?.emission?.toLocaleString('nl', {maximumFractionDigits: 2})}</DisplayEmission>
                                </TotalEmission>
                            </Flex>

                            <SupplyChainChart key={selectedSupplyChain} id="perSubLabel" emission={data} labels={labels} dataValues={dataValues} selectedSupplyChain={selectedSupplyChain} />
                        </Section>
                    }
                </MyFootprintWrapper>
            </Container>
        </Page>
    );
}