import styled, {css, keyframes} from 'styled-components';
import background from "../../assets/background.svg";

export const truncateMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ $clamp = 1 }) => `-webkit-line-clamp: ${$clamp};`}
`;

const rotating = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

export const SpinnerWrapper = styled.div`
    min-height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media screen and (max-width: 768px) {
        min-height: 500px;
    }
`;

export const Spinner = styled.div`
    width: ${({$size = "default"}) => $size === "default" ? '50px' : '50px'};
    height: ${({$size = "default"}) => $size === "default" ? '50px' : '50px'};
    border-radius: 50%;
    border: 5px solid var(--color-green);
    border-top: 5px solid transparent;
    animation: ${rotating} 2s linear infinite;
`;

export const Container = styled.div`
    max-width: 1260px;
    margin: 0 auto;
    padding: ${({$noPadding}) => $noPadding ? '0' : '0 20px'};
`;

export const WrappedContainer = styled.section`
    max-width: 860px;
    margin: 0 auto;
    padding: ${({$noPadding}) => $noPadding ? '0' : '0 20px 50px'};
    
    @media screen and (max-width: 768px) {
        padding: ${({$noPadding}) => $noPadding ? '0' : '0 20px 30px'};
    }
`;

export const Page = styled.div`
    padding-top: 220px;
    padding-bottom: 160px;

    @media screen and (max-width: 768px) {
        padding-top: 170px;
        padding-bottom: 106px;
    }
    
    ${({$withBackground, $withBackgroundOnMobile}) => $withBackground && `
        background-image: url(${background}),url(${background});
        background-attachment: fixed;
        background-size: auto;
        background-repeat: no-repeat;
        background-position: -40% 600px, 
                              80% 40px;
        
        ${$withBackgroundOnMobile ? `
            @media screen and (max-width: 1024px) {
                background-attachment: inherit;
                background-position: -300% 600px,
                                      250% 35px;
            }
        
            @media screen and (max-width: 768px) {
                background-position: -40% 600px,
                                      33% -40px;
            }
        ` : `
            @media screen and (max-width: 1024px) {
                background-image: none;
            }
        `}
    `};
`;

export const ConditionalWrap = ({condition, wrap, fallback, children}) => {
    return condition ? wrap(children) : fallback ? fallback(children) : children;
}