import GuestHome from './GuestHome';
import AuthenticatedHome from './AuthenticatedHome';
import Cookies from 'js-cookie';
import {useRouteLoaderData} from 'react-router-dom';

export async function homeLoader({request}) {
    const hasAccessToken = Boolean(Cookies.get('kern_token'));

    if(hasAccessToken) {
        const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/solutions`, {
            headers: {
                Authorization: `Bearer ${Cookies.get('kern_token')}`
            },
            signal: request.signal
        });

        if(!res.ok) {
            // Access token is probably invalid, so try to fetch public home data
            if(res.status === 401) return await fetchPublicHomeData(request);

            throw new Response("Something went wrong", { status: res.status });
        }

        const data = await res.json();

        return {data};
    }

    // If the user doesn't have an access token, fetch public home data
    return await fetchPublicHomeData(request);
}

async function fetchPublicHomeData(request) {
    Cookies.remove('kern_token');

    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/home`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page not found", { status: 404 });
    }

    const data = await res.json();

    return {data};
}

export default function Home() {
    const {checkLoginData} = useRouteLoaderData("root");

    if(checkLoginData?.isLoggedIn) return <AuthenticatedHome />

    return <GuestHome />
}