import styled from "styled-components";
import star from '../../assets/icons/star.svg';
import HiddenField from './HiddenField';

const StyledInput = styled.input`
    --dir: right;
    --fill: #FFCD3C;
    --fillbg: var(--color-border);
    --star: url(${star});
    --stars: 5;
    --starsize: 3rem;
    --symbol: var(--star);
    --w: calc(var(--stars) * var(--starsize));
    --x: calc(100% * (${props => (props.value)} / var(--stars)));
    block-size: var(--starsize);
    inline-size: var(--w);
    position: relative;
    touch-action: manipulation;
    -webkit-appearance: none;
    margin-top: 10px;
    
    [dir="rtl"] {
        --dir: left;
    }
    ::-moz-range-track {
        background: linear-gradient(to var(--dir), var(--fill) 0 var(--x), var(--fillbg) 0 var(--x));
        block-size: 100%;
        mask: repeat left center/var(--starsize) var(--symbol);
    }
    ::-webkit-slider-runnable-track {
        background: linear-gradient(to var(--dir), var(--fill) 0 var(--x), var(--fillbg) 0 var(--x));
        block-size: 100%;
        mask: repeat left center/var(--starsize) var(--symbol);
        -webkit-mask: repeat left center/var(--starsize) var(--symbol);
    }
    ::-moz-range-thumb {
        height: var(--starsize);
        opacity: 0;
        width: var(--starsize);
    }
    ::-webkit-slider-thumb {
        height: var(--starsize);
        opacity: 0;
        width: var(--starsize);
        -webkit-appearance: none;
    }
`

const StyledLabel = styled.label`
    display: block;
    font-family: ui-sans-serif, system-ui, sans-serif;
    margin-block-end: 1rem;
`

const StarField = ({children, ...props}) => {
    // eslint-disable-next-line

    return (
        <>
            <HiddenField/>
            <StyledLabel>
                <StyledInput
                    type="range"
                    value={props.value !== '' ? props.value : 0}
                    max="5"
                    step="1"
                    {...props.register(props.name, {
                        onChange: (e) => {props.fieldBlur()},
                    })}
                />
            </StyledLabel>
        </>
    )
}

export default StarField