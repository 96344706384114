import {Link, useLoaderData} from 'react-router-dom';
import {Container, Page} from '../../components/Utils/Utils';
import {Heading2} from '../../components/Heading/Heading';
import styled from 'styled-components';
import Button from '../../components/Button/Button';
import SolutionCard from '../../components/SolutionCard/SolutionCard';
import {Company} from '../../components/Company/Company';

const Cards = styled.div`
    display: flex;
    gap: ${({$isNews}) => $isNews ? '30px' : '20px'};
    padding: 12px 20px;
    overflow-x: auto;

    @media screen and (max-width: 768px) {
        gap: 20px;
    }
`;

const Card = styled(Link)`
    color: inherit;
    text-decoration: none;
    width: calc((100% - 60px) / ${({$isNews}) => $isNews ? '3' : '4'});
    min-width: 200px;
    display: inline-block;
    
    @media screen and (max-width: 768px) {
        ${({$isNews}) => $isNews && 'width: calc((100% - 40px) / 3);'};
    }
`;

const StyledHeading2 = styled(Heading2)`
    margin-bottom: 6px;

    @media screen and (max-width: 768px) {
        margin-bottom: 0;
    }
`;

const StyledContainer = styled(Container)`
    padding: 0;
`;

const Sections = styled.div`
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    
    @media screen and (max-width: 768px) {
        margin-top: 40px;
        gap: 10px;
    }
`;

const SectionHeader = styled(Container)`
    display: flex;
    justify-content: space-between;
    gap: 10px;
`;

export default function AuthenticatedHome() {
    const {data} = useLoaderData();

    return (
        <Page $withBackground>
            <Company regions={data?.regions} introText={data?.introText} emission={data?.CO2Emission}>{data?.company}</Company>

            <Sections>
                {data?.items?.map(item => (
                    <div key={item.id}>
                        <SectionHeader>
                            <StyledHeading2>{item.title}</StyledHeading2>
                            {((item.type === "news" && item.solutionDetails?.length >= 3) || item.solutionDetails?.length >= 4) &&
                                <Button to={`/${item.slug}`} $outline $secondary>Alle</Button>
                            }
                        </SectionHeader>
                        <StyledContainer>
                            <Cards $isNews={item.type === "news"} $amount={item.solutionDetails?.length}>
                                {item.solutionDetails?.map(card => (
                                    <Card key={card.id} to={`/${item.slug}/${card.slug}`} state={{fromHome: true}} $isNews={item.type === "news"}>
                                        <SolutionCard data={card} type={item.type}>{card.title}</SolutionCard>
                                    </Card>
                                ))}
                            </Cards>
                        </StyledContainer>
                    </div>
                ))}
            </Sections>
        </Page>
    );
}
