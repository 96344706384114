import styled, {keyframes} from 'styled-components';
import {Link} from 'react-router-dom';
import {HiOutlineArrowRight, HiOutlineArrowLeft} from 'react-icons/hi';
import {AiOutlineLoading3Quarters} from 'react-icons/ai';

const StyledButton = styled(Link)`
    background-color: ${props => ((props.$outline || props.$previous) && !props.$secondary) ? 'var(--color-white)' : (props.$secondary || props.$transparent) ? 'transparent' : 'var(--color-dark-green)'}; 
    color: ${props => (props.$outline || props.$previous) ? 'var(--color-dark-green)' : 'var(--color-white)'};
    font-family: var(--font-mulish);
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    border-radius: 10px;
    padding: ${props => (props.$secondary || props.$transparent) ? '0' : '15px 18px'};
    text-decoration: none;
    display: inline-flex;
    flex-direction: ${props => props.$previous ? 'row-reverse' : 'row'};
    align-items: center;
    box-shadow: ${props => ((props.$outline || props.$previous) && !props.$secondary) ? 'inset 0px 0px 0px 2px var(--color-dark-green)' : 'none'};
    border: none; // Reset button style
    cursor: pointer; // Reset button style
    
    :active {
        background-color: ${props => ((props.$outline || props.$previous) && !props.$secondary) ? 'var(--back-button-pressed)' : (props.$secondary || props.$transparent) ? 'transparent' : 'var(--next-button-pressed)'};
    }
`;

const ArrowLeft = styled(HiOutlineArrowLeft)`
    color: var(--color-green);
    margin-right: 15px;
    transition: transform .2s ease;
    
    ${StyledButton}:hover & {
        transform: translateX(-4px);
    }
`;

const ArrowRight = styled(HiOutlineArrowRight)`
    color: var(--color-green);
    margin-left: 15px;
    transition: transform .2s ease;
    
    ${StyledButton}:hover & {
        transform: translateX(4px);
    }
`;

const loading = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

const LoadIcon = styled(AiOutlineLoading3Quarters)`
    color: var(--color-green);
    margin-left: 20px;
    animation: ${loading} 2s linear infinite;
`;

const Button = ({className, children, previous, loading, ...props}) => {

    return (
        <StyledButton className={className} $previous={previous} {...props}>
            {children}
            {loading ? <LoadIcon size={'1.5em'}/> : (previous ?  <ArrowLeft size={'1.5em'} /> : <ArrowRight size={'1.5em'}/>)}
        </StyledButton>
    )
}

export default Button