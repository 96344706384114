import styled from 'styled-components';

const StyledProgressBar = styled.div`
    display: flex;
    margin-bottom: 60px;

    @media screen and (max-width: 768px) {
        margin-bottom: 40px;
    }
`;

const Step = styled.div`
    height: 10px;
    width: 100%;
    border-radius: 10px;
    background-color: ${props => props.$active ? 'var(--color-green)' : 'rgba(var(--rgb-dark-green), .1)'};
    
    &:not(:last-of-type) {
        margin-right: 10px;
    }
`;

const ProgressBar = ({activeStep, maxSteps}) => {

    return (
        <StyledProgressBar>
            {[...Array(maxSteps)].map((item, index) => (
                <Step key={index} $active={activeStep === index} />
            ))}
        </StyledProgressBar>
    )
}

export default ProgressBar