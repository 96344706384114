import styled from "styled-components";
import RequiredText from '../RequiredText/RequiredText';
import Input from '../Input/Input';

const StyledTextField = styled(Input)`
    height: unset;
    resize: vertical;
`

const TextAreaField = ({children, ...props}) => {
    return (
        <div>
            <StyledTextField
                as="textarea"
                rows={6}
                type="text"
                placeholder={props.placeholder}
                defaultValue={props.value}
                {...props.register(props.name, {
                    required: props.required,
                    onBlur: () => { if (props.fieldBlur) props.fieldBlur()}
                })}
                errors={props.errors}
            />
            {props.errors && <RequiredText>Dit veld is verplicht</RequiredText>}
        </div>
    )
}

export default TextAreaField