import {Page, WrappedContainer} from '../../components/Utils/Utils';
import {Heading2} from '../../components/Heading/Heading';
import styled from 'styled-components';
import Button from '../../components/Button/Button';
import {useForm} from 'react-hook-form';
import React, {useEffect, useRef, useState} from 'react';
import Question from '../../components/Question/Question';
import {useActionData, useNavigation, useSubmit} from 'react-router-dom';
import {Text} from '../../components/Text/Text';

const Outer = styled.div`
    padding: 0 20px;
    
    @media screen and (max-width: 580px) {
        padding: 0;
    }
`;

const Inner = styled.article`
    border-radius: 20px;
    padding: 40px;
    box-shadow: var(--bs-form);
    background-color: var(--color-white);

    ${Heading2} {margin-bottom: 30px; }

    & form > div:nth-of-type(1) {
        margin-bottom: 30px;
    }
    
    @media screen and (max-width: 768px) {
        padding: 30px 20px;
    }

    @media screen and (max-width: 580px) {
        border-radius: 0;
        ${Heading2} {margin-bottom: 10px; }
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
`;

export async function resetPasswordAction({request}) {
    let formData = await request.formData();

    const body = {
        "email": formData.get("email"),
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reset-password`, {
            signal: request.signal,
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if(!response.ok) {
            throw new Response("Something went wrong", { status: response.status });
        }

        await response.json();

        return { "success": true };
    } catch(error) {
        return { "error": error };
    }
}

export default function ResetPassword() {
    const {register, handleSubmit, setError, formState: {errors}} = useForm();
    const [status, setStatus] = useState('idle');
    const actionData = useActionData();
    const navigation = useNavigation();
    const submit = useSubmit();
    let prevState = useRef('idle');

    useEffect(() => {
        if((prevState.current === 'loading' && navigation.state === 'idle') && actionData?.error) {
            setStatus('idle');
            setError('email', { type: 'required', message: 'Het wachtwoord kan niet gereset worden voor dit e-mailadres'})
        }

        if(actionData?.success) {
            setStatus('success')
        }

        prevState.current = navigation.state;
    }, [actionData, navigation.state, setError])

    const onSubmit = async values => {
        setStatus('submitting');
        submit(values, { method: "post", action: "/wachtwoord-resetten" });
    }

    return (
        <Page $withBackground>
            <WrappedContainer $noPadding>
                <Outer>
                    <Inner>
                        <Heading2>Wachtwoord resetten</Heading2>

                        {status === 'success' ? (
                            <Text>Je ontvangt een e-mail met daarin een link om een nieuw wachtwoord in te stellen.</Text>
                        ) : (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Question
                                    key="email"
                                    field={{id: "email", title: "E-mailadres", type: "email", required: 1}}
                                    placeholder="E-mailadres"
                                    register={register}
                                    errors={errors["email"]}
                                    lessMarginOnMobile={true}
                                    hideRequired={true}
                                />

                                <ButtonWrapper>
                                    <Button as="button" disabled={status === "submitting"} loading={status === "submitting"} type="submit">Versturen</Button>
                                </ButtonWrapper>
                            </form>
                        )}
                    </Inner>
                </Outer>
            </WrappedContainer>
        </Page>
    );
}