import {Link, redirect, useLoaderData, useParams} from 'react-router-dom';
import {Container, Page, Spinner} from '../../components/Utils/Utils';
import {Company} from '../../components/Company/Company';
import styled from 'styled-components';
import {Heading2} from '../../components/Heading/Heading';
import SolutionCard from '../../components/SolutionCard/SolutionCard';
import {useState} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Cookies from 'js-cookie';

const Content = styled(Container)`
    margin-top: 60px;

    @media screen and (max-width: 768px) {
        margin-top: 40px;
    }
`;

const Grid = styled.div`
    padding-bottom: 80px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }
`;

const Item = styled(Link)`
    color: inherit;
    text-decoration: none;
    display: inline-block;
`;

const StyledHeading2 = styled(Heading2)`
    margin-bottom: 18px;

    @media screen and (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

const SpinnerWrapper = styled.div`
    ${Spinner} {
        margin: 0 auto;
    }
`;

export async function solutionsOverviewLoader({request, params}) {
    const hasAccessToken = Boolean(Cookies.get('kern_token'));
    if(!hasAccessToken) return redirect('/inloggen');

    const [solutionsData, detailsData] = await Promise.all([
        fetch(`${process.env.REACT_APP_API_BASE_URL}/solutions/${params.slug}`, {
            headers: {
                Authorization: `Bearer ${Cookies.get('kern_token')}`
            },
            signal: request.signal
        }).then((res) => {
            if (!res.ok) {
                // Redirect to login if Unauthorized
                if(res.status === 401) {
                    Cookies.remove('kern_token');

                    throw new Response("", {
                        status: 302,
                        headers: {
                            Location: "/inloggen",
                        },
                    });
                }

                throw new Response("Page not found", { status: 404 });
            }

            return res.json();
        }),

        fetch(`${process.env.REACT_APP_API_BASE_URL}/solutions/${params.slug}/details?page[size]=9&page[number]=1`, {
            headers: {
                Authorization: `Bearer ${Cookies.get('kern_token')}`
            },
            signal: request.signal
        }).then((res) => {
            if (!res.ok) {
                // Redirect to login if Unauthorized
                if(res.status === 401) {
                    Cookies.remove('kern_token');

                    throw new Response("", {
                        status: 302,
                        headers: {
                            Location: "/inloggen",
                        },
                    });
                }

                throw new Response("Page not found", { status: 404 });
            }

            return res.json();
        })
    ])

    return ({solutionsData, detailsData});
}

export default function SolutionsOverview() {
    const {solutionsData, detailsData} = useLoaderData();

    // Infinite scroll
    let {slug} = useParams();
    let count = detailsData.count;
    const [data, setData] = useState(detailsData.items);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(count !== data.length);
    const [error, setError] = useState();
    const [page, setPage] = useState(2);

    async function loadMore() {
        setLoading(true);

        try {
            const fetchedData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/solutions/${slug}/details?page[size]=9&page[number]=${page}`, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('kern_token')}`
                },
            }).then((res) => {
                if (!res.ok) {
                    throw new Response("Bad request", { status: 400 });
                }

                return res.json();
            })

            setData([...data, ...fetchedData.items]);
            setPage(page + 1);

            if (count === [...data, ...fetchedData.items].length) setHasNextPage(false);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }

    const [sentryRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        disabled: !!error,
        rootMargin: '0px 0px 0px 0px',
    });

    return (
        <Page $withBackground>
            <Company regions={solutionsData.regions} compact>{solutionsData.company}</Company>

            <Content>
                <StyledHeading2>{solutionsData.solution.title}</StyledHeading2>

                <Grid>
                    {data?.map(item => (
                        <Item key={item.id} to={item.slug}>
                            <SolutionCard data={item} type={solutionsData.solution.type} overview>{item.title}</SolutionCard>
                        </Item>
                    ))}
                </Grid>

                {(loading || hasNextPage) && (
                    <SpinnerWrapper ref={sentryRef}>
                        <Spinner />
                    </SpinnerWrapper>
                )}
            </Content>
        </Page>
    );
}