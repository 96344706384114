import styled from 'styled-components';

const Outer = styled.section`
    max-width: 860px;
    margin: 0 auto;
    padding: 0 20px;

    @media screen and (max-width: 580px) {
        padding: 0;
    }
`;

const Inner = styled.div`
    border-radius: 20px;
    padding: 40px;
    box-shadow: var(--bs-form);
    background-color: var(--color-white);

    @media screen and (max-width: 768px) {
        padding: 30px 20px 20px;
    }

    @media screen and (max-width: 580px) {
        border-radius: 0;
    }
`;

const CardContainer = ({children, ...props}) => {
    return (
        <Outer {...props}>
            <Inner>
                {children}
            </Inner>
        </Outer>
    )
}

export default CardContainer