import styled from 'styled-components';
import {useEffect, useState} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {MdOutlineDragIndicator} from 'react-icons/md';
import {useFormData} from '../../contexts/FormContext';

const Box = styled.div`
    background-color: var(--color-drag-fill);
    border: 1px solid var(--color-drag-stroke);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: 7px;
    font-size: var(--fs-text);
    font-weight: var(--fw-regular);
    color: var(--color-grey);

    &.hovering {
        box-shadow: var(--bs-drag);
    }
`;

const DragIcon = styled(MdOutlineDragIndicator)`
    transform: rotate(90deg);
    color: var(--color-green);
`;

const List = styled.ul`
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;
`;

const RankingField = ({children, ...props}) => {
    const formReply = useFormData();
    const [options, setOptions] = useState();

    useEffect(() => {
        let contextInfo = formReply.values[props.name];

        if(contextInfo){
            let result = props.options?.sort((a, b) => contextInfo.indexOf(a.id) - contextInfo.indexOf(b.id));
            setOptions(result);
            return;
        }

        setOptions(props.options)
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        props.fieldBlur();
    // eslint-disable-next-line
    }, [options])

    const dragEnded = (param) => {
        const { source, destination } = param;
        if (source == null || destination == null) return;

        let arr = [...options];
        const item = arr.splice(source.index, 1)[0]; // Extracting the source item from the list
        arr.splice(destination.index, 0, item); // Inserting it at the destination index
        setOptions(arr);

        // Unregister the options on drag Ended
        options.forEach((option) => {
            props.unregister(props.name)
        })

    };

    return (
        <>
            <DragDropContext onDragEnd={dragEnded}>
                <Droppable droppableId="rankingField">
                    {(provided) => (
                        <List ref={provided.innerRef} {...provided.droppableProps}>
                            {options?.map((option, index) => (
                                <Draggable key={option.id} draggableId={`option-${option.id}`} index={index}>
                                    {(provided, snapshot) => (
                                        <>
                                            <Box
                                                className={snapshot.isDragging && 'hovering'}
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                            >
                                                {index + 1}. {option.title} <DragIcon size={'1.5em'} />
                                            </Box>
                                            <input type='checkbox' value={option.id} defaultChecked={true} hidden
                                                {...props.register(`${props.name}.${index}`)}
                                            />
                                        </>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    )
}

export default RankingField