import {createContext, useState, useContext} from "react";

export const FormContext = createContext(null);

export default function FormProvider({children}) {
    const [formReplyID, setFormReplyID] = useState();
    const [step, setStep] = useState(0);
    const [virtualStep, setVirtualStep] = useState(2);
    const [values, setValues] = useState({});
    const [config, setConfig] = useState({});

    const setFormValues = (data) => {
        setValues((prevValues) => ({
            ...prevValues,
            ...data,
        }));
    };

    const setFormConfig = (data) => {
        setConfig((prevData) => ({
            ...prevData,
            ...data,
        }));
    };

    const clearFormContext = () => {
        setFormReplyID(null);
        setStep(0);
        setVirtualStep(2);
        setValues({});
        setConfig({});
    }

    return (
        <FormContext.Provider value={{values, setValues, step, setStep, virtualStep, setVirtualStep, formReplyID, setFormReplyID, setFormValues, config, setFormConfig, clearFormContext}}>
            {children}
        </FormContext.Provider>
    );
}

export const useFormData = () => useContext(FormContext);

