import {useMatch, useNavigate} from 'react-router-dom';

export const useQuickScanLink = () => {
    const navigate = useNavigate();
    const match = useMatch('/quick-scan/:slug')
    const isInQuickScan = Boolean(match && match?.params?.slug !== "success");

    const handleLink = (url, isLoggedIn) => {
        if (window.confirm(`Je staat op het punt de Quick Scan af te sluiten. Alle ${isLoggedIn ? "gewijzigde en niet opgeslagen" : "ingevulde"} data zal verloren gaan. Weet je zeker dat je de Quick Scan wil ${isLoggedIn ? "afsluiten" : "stoppen"}?`) === true) {
            navigate(url);
        }
    };

    return { isInQuickScan, handleLink };
};