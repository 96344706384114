import styled from 'styled-components';
import calendar from '../../assets/icons/calendar.svg';
import Input from '../Input/Input';
import RequiredText from '../RequiredText/RequiredText';

const StyledDateField = styled(Input)`
    position: relative;
    
    ::-webkit-inner-spin-button, ::-webkit-calendar-picker-indicator {
        opacity: 0;
    }
    
    ::-webkit-date-and-time-value {
        text-align: left;
    }

    background-image: url(${calendar});
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
`

const DateField = ({children, ...props}) => {
    return (
            <>
                <StyledDateField
                    type="date"
                    defaultValue={props.value}
                    {...props.register(props.name, {
                        required: props.required,
                        onBlur: (e) => {props.fieldBlur()},
                    })}
                    errors={props.errors}
                />
                {props.errors && <RequiredText>Dit veld is verplicht</RequiredText>}
            </>
    )
}

export default DateField;