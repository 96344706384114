import styled from 'styled-components';
import {Heading3} from '../Heading/Heading';
import {Text} from '../Text/Text';
import {HorizontalImageInner, HorizontalImageOuter} from '../HorizontalImage/HorizontalImage';
import placeholder from '../../assets/solutions-placeholder.jpg';

const StyledSolutionCard = styled.article`
    box-shadow: var(--bs-block);
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
`;

const Inner = styled.div`
    background-color: var(--color-white);
    border-radius: 10px;
    flex: 1;
    padding: ${({$isNews}) => $isNews ? '30px' : '20px'};
    margin-top: -10px;
    z-index: 1;
    
    ${({$isNews}) => !$isNews && `
        display: flex;
        align-items: center;
    `};

    @media screen and (max-width: 768px) {
        padding: ${({$isNews, $overview}) => ($isNews && $overview) ? '25px' : $overview ? '20px' : '15px'};
    }
`;

const PublicationDate = styled.p`
    font-size: var(--fs-data);
    color: var(--color-dark-green);
    line-height: 1;
    text-transform: lowercase;
`;

const StyledHorizontalImageOuter = styled(HorizontalImageOuter)`
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden;
`;

const StyledHeading3 = styled(Heading3)`
    ${StyledSolutionCard}:hover & {
        text-decoration: underline;
    }
    
    ${({$isNews}) => $isNews && `
        min-height: 50px; 
    
        @media screen and (max-width: 768px) {
            min-height: 40px;
        }
    `}
`;

const StyledText = styled(Text)`
    margin-bottom: 0;

    ${({$overview}) => !$overview && `
        @media screen and (max-width: 768px) {
            display: none;
        }
    `}
`;

const SolutionCard = ({data, type, overview = false, children}) => {
    const isNews = type === "news";

    return (
        <StyledSolutionCard>
            <StyledHorizontalImageOuter>
                <HorizontalImageInner src={data.image || placeholder} alt="" />
            </StyledHorizontalImageOuter>
            <Inner $isNews={isNews} $overview={overview}>
                <StyledHeading3 $truncate $clamp={2} $isNews={isNews}>{children}</StyledHeading3>
                {(isNews && data.publicationDate) && <PublicationDate>{data.publicationDate}</PublicationDate>}
                {(isNews && data.introText) && <StyledText $overview={overview} $truncate $clamp={5}>{data.introText}</StyledText>}
            </Inner>
        </StyledSolutionCard>
    );
}

export default SolutionCard;