import {useFormData} from "../../contexts/FormContext";
import RequiredText from "../RequiredText/RequiredText";
import styled from "styled-components";
import check from '../../assets/icons/check.svg';
import HiddenField from './HiddenField';
import Input from '../Input/Input';

const StyledTextField = styled(Input)`
    display: block;
    margin: 13px 0px 0px 35px;
    width: calc(100% - 35px);
`

const Wrapper = styled.div`
    margin-top: 12px;
    display: flex;
    ${({$other}) => $other && 'flex-wrap: wrap'};
`

const CheckBoxInput = styled.input`
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 5px;
    vertical-align: middle;
    border: 1.5px solid #DCDCDC;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0 13px 0 0;

    &:checked {
        border: 0;
        background-image: url(${check});
        background-color: var(--color-green);
        background-size: 65% 65%;
    }
`

const CheckBoxLabel = styled.label`
    vertical-align: middle;
    font-size: var(--fs-text);
    color: var(--color-grey);
    font-weight: var(--fw-regular);
`

const HiddenCheckBox = styled.input`
    display: none;
`;

const CheckBoxField = ({children, ...props}) => {
    const formReply = useFormData();

    const getOther = (questionId, optionId) => {
        const selectedArray = formReply.values[questionId];

        if (selectedArray && selectedArray.includes(optionId)) {
            return formReply.values[`other-${optionId}`];
        } else {
            return "";
        }
    }

    return (
        <>
            <HiddenField/>
            {props.options.map((option) => {
                return (
                    <Wrapper key={option.id} $other={option.type === 'other'}>
                        <CheckBoxInput type='checkbox' id={option.id} value={option.id}
                            {...props.register(props.name, {
                                required: props.required,
                                onChange: (e) => {props.fieldBlur()},
                            })}
                        />
                        <CheckBoxLabel htmlFor={option.id}>{option.title}</CheckBoxLabel>
                        {(option.type === 'other' && Array.isArray(props.value) && props.value.includes(option.id)) &&
                            <StyledTextField type="text" defaultValue={getOther(props.name, option.id)}
                                 {...props.register(`other-${option.id}`, {
                                     onBlur: (e) => {props.fieldBlur()},
                                 })}
                            />
                        }
                    </Wrapper>
                )
            })}

            <HiddenCheckBox type='checkbox' id={`hidden-${props.name}`} name={props.name} value={`hidden-${props.name}`}
               {...props.register(props.name, {
                   onChange: (e) => {props.fieldBlur()},
               })}
            />

            {props.errors && <RequiredText>Dit veld is verplicht</RequiredText>}
        </>
    )
}

export default CheckBoxField