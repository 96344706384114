import GlobalStyle from '../../globalStyles';
import {Outlet, ScrollRestoration, useLocation, useNavigation} from 'react-router-dom';
import {useEffect} from 'react';
import nProgress from 'nprogress';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import ErrorPage from '../ErrorPage/ErrorPage';
import Cookies from 'js-cookie';
import {useFormData} from '../../contexts/FormContext';
import CookieConsent from '../../components/CookieConsent/CookieConsent';

export async function rootLoader({request}) {
    const hasAccessToken = Boolean(Cookies.get('kern_token'));
    if(hasAccessToken) {
        const [checkLoginData, headerFooterData] = await Promise.all([
            fetch(`${process.env.REACT_APP_API_BASE_URL}/check-login`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${Cookies.get('kern_token')}`
                },
                signal: request.signal
            }).then((res) => {
                if (!res.ok) {
                    if(res.status === 401) Cookies.remove('kern_token');

                    throw new Response("Bad request", { status: 400 });
                }

                return res.json();
            }),

            fetch(`${process.env.REACT_APP_API_BASE_URL}/headerfooter`, {
                signal: request.signal
            }).then((res) => {
                if (!res.ok) {
                    throw new Response("Bad request", { status: 400 });
                }

                return res.json();
            })
        ]);

        return ({checkLoginData, data: headerFooterData});
    };

    // If the user doesn't have an access token, a check login is not needed
    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/headerfooter`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Bad request", { status: 400 });
    }

    const data = await res.json();

    return {data};
}

const Root = () => {
    const location = useLocation();
    const navigation = useNavigation();
    const {clearFormContext} = useFormData()

    // NProgress bar (loadingbar on page change)
    nProgress.configure({
        showSpinner: false,
        template: '<div class="bar" role="bar"></div>',
        minimum: 0.3,
        speed: 400,
    });

    // Normal navigation in data router is idle -> loading -> idle, so start nProgress if state changed to "loading"
    if(navigation.state === "loading") {
        nProgress.start();
    }

    if(navigation.state === "idle") {
        nProgress.done();
    }

    // When the location changed, complete the nProgress
    useEffect(() => {
        nProgress.done();

        // Clear form context if navigating to other page
        if(!location.pathname.startsWith('/quick-scan')) {
            clearFormContext();
        }
    // eslint-disable-next-line
    }, [location])

    return (
        <>
            <GlobalStyle />
            {location.pathname.includes("/quick-scan") ? <ScrollToTop /> : <ScrollRestoration />}
            <Navbar />
            <Outlet />
            <Footer />
            <CookieConsent />
        </>
    )
}

export const RootError = () => {
    return (
        <>
            <GlobalStyle />
            <ScrollToTop />
            <ErrorPage />
        </>
    )
}

export default Root;