import styled from 'styled-components';

const StyledPagination = styled.p`
    font-size: var(--fs-pagination);
    color: rgba(var(--rgb-color-grey), .6);
    display: inline;
    margin: 0;
    font-weight: var(--fw-regular);
`;

const Pagination = ({children}) => {

    return (
        <StyledPagination>
            {children}
        </StyledPagination>
    )
}

export default Pagination