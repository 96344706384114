import {useLoaderData} from 'react-router-dom';
import {PageTitle} from '../../components/Heading/Heading';
import {Page, WrappedContainer} from '../../components/Utils/Utils';
import CardContainer from '../../components/CardContainer/CardContainer';
import {RichTextHolder} from '../../components/Text/Text';

export async function privacyPolicyLoader({request}) {
    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/privacypolicy`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page not found", { status: 404 });
    }

    const data = await res.json();

    return {data};
}

export default function PrivacyPolicy() {
    const {data} = useLoaderData();

    return (
        <Page>
            <WrappedContainer>
                <PageTitle>{data.privacyTitle}</PageTitle>
            </WrappedContainer>

            <CardContainer>
                <RichTextHolder dangerouslySetInnerHTML={{__html: data.privacyText}} />
            </CardContainer>
        </Page>
    );
}