import styled from 'styled-components';
import time from '../../assets/icons/time.svg';
import check from '../../assets/icons/check.svg';
import RequiredText from '../RequiredText/RequiredText';

const StyledSelect = styled.select`
    margin-bottom: 10px;

    margin-top: 10px;
    width: 100%;
    padding: 12.5px;
    
    border:solid 1.5px ${props => props?.errors ? "var(--color-required)" : "var(--color-border)"};
    border-radius: 10px;

    color: var(--color-grey);
    
    font-size: var(--fs-text);
    font-weight: var(--fw-regular);
    font-family:inherit;

    background: transparent;
    
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(${time});
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    
    :focus {
        outline: none;
        border: solid 1.5px var(--color-green)
    }
`;

const CheckBoxInput = styled.input`
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 5px;
    vertical-align: middle;
    border: 1.5px solid #DCDCDC;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0 13px 0 0;

    &:checked {
        background-color: var(--color-green);
        background-image: url(${check});
        background-size: 65% 65%;
    }
`

const CheckBoxLabel = styled.label`
    vertical-align: middle;
    font-size: var(--fs-text);
    color: var(--color-grey);
    font-weight: var(--fw-regular);
`

const TimeField = ({children, ...props}) => {

    const getTimes = () => {
        const times = [];
        const date = new Date();
        const format = {
            hour: 'numeric',
            minute: 'numeric',
        };

        for (let minutes = 0; minutes < 24 * 60; minutes = minutes + 15) {
            date.setHours(0);
            date.setMinutes(minutes);
            times.push(date.toLocaleTimeString('nl-NL', format));
        }

        return times;
    }

    return (
            <>
                <StyledSelect
                    defaultValue={props.value}
                    {...props.register(props.name, {
                      required: props.required,
                      onBlur: (e) => {props.fieldBlur()},
                    })}
                    errors={props.errors}
                >
                    <option value=""></option>
                    {getTimes().map((option) => {
                        return (
                            <option key={option} value={option}>{option}</option>
                        )
                    })}
                </StyledSelect>

                <div>
                    <CheckBoxInput type='checkbox' id={`different-${props.name}`}
                        {...props.register(`different-${props.name}`, {
                           onChange: (e) => {props.fieldBlur()},
                        })}
                    />
                    <CheckBoxLabel htmlFor={`different-${props.name}`}>Verschillend</CheckBoxLabel>
                </div>
                {props.errors && <RequiredText>Dit veld is verplicht</RequiredText>}
            </>
    )
}

export default TimeField;