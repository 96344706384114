import styled from "styled-components";
import RequiredText from '../RequiredText/RequiredText';
import Input from '../Input/Input';

const StyledPasswordField = styled(Input)``

const PasswordField = ({children, ...props}) => {
    return (
        <div>
            <StyledPasswordField
                type="password"
                placeholder={props.placeholder}
                defaultValue={props.value}
                {...props?.register(props.name, {
                    required: "Wachtwoord is verplicht",
                    ...(props.name === 'password' && {
                        minLength: {
                            value: 6,
                            message: "Minimaal 6 karakters"
                        },
                    }),
                    ...(props.name === 'password-repeat' && {
                        validate: (val) => {
                            if (props.watch('password') !== val) {
                                return "Wachtwoorden komen niet overeen";
                            }
                        }
                    }),
                })}
                errors={props.errors}
            />
            {props.errors && <RequiredText>{props.errors.message}</RequiredText>}
        </div>
    )
}

export default PasswordField