import {Page, WrappedContainer} from '../../components/Utils/Utils';
import {Heading2} from '../../components/Heading/Heading';
import {HorizontalImageInner, HorizontalImageOuter} from '../../components/HorizontalImage/HorizontalImage';
import styled from 'styled-components';
import {RichTextHolder} from '../../components/Text/Text';
import React from 'react';
import {useLoaderData} from 'react-router-dom';

const AboutWrapper = styled.div`
    padding: 0 20px;
    
    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

const Card = styled.article`
    box-shadow: var(--bs-block);
    border-radius: 20px;
    
    @media screen and (max-width: 768px) {
        border-radius: 0;
    }
`;

const StyledHorizontalImageOuter = styled(HorizontalImageOuter)`
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    overflow: hidden;
    
    @media screen and (max-width: 768px) {
        border-radius: 0;
    }
`;

const Inner = styled.div`
    background-color: var(--color-white);
    border-radius: 20px;
    padding: 40px;
    ${({$hasImage}) => $hasImage && 'margin-top: -20px;'};
    z-index: 1;
    position: relative;
    
    ${Heading2} {
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        border-radius: ${({$hasImage}) => $hasImage ? '10px 10px 0 0' : '0'};
        padding: 20px;
    }
`;

export async function aboutLoader({request}) {
    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/about`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page not found", { status: 404 });
    }

    const data = await res.json();

    return {data};
}

export default function About() {
    const {data} = useLoaderData();

    return (
        <Page $withBackground>
            <WrappedContainer $noPadding>
                <AboutWrapper>
                    <Card>
                        {data.image &&
                            <StyledHorizontalImageOuter>
                                <HorizontalImageInner src={data.image} alt=""/>
                            </StyledHorizontalImageOuter>
                        }
                        <Inner $hasImage={Boolean(data.image)}>
                            <Heading2>{data.title}</Heading2>
                            {data.text && <RichTextHolder dangerouslySetInnerHTML={{__html: data.text}} $compactHeading />}
                        </Inner>
                    </Card>
                </AboutWrapper>
            </WrappedContainer>
        </Page>
    );
}