import styled, {css} from 'styled-components';
import {heading2Styles, heading3Styles} from '../Heading/Heading';
import {truncateMixin} from '../Utils/Utils';

export const textStyles = css`
    font-size: var(--fs-text);
    color: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-dark-green)'};
    line-height: 1.35;
    overflow-wrap: anywhere;
`;

export const Text = styled.p`
    ${textStyles};
    ${({ $truncate }) => $truncate && truncateMixin};
    ${({ $preWrap }) => $preWrap && 'white-space: pre-wrap;'};
`;

export const RichTextHolder = styled.div`
    p, ul, ol {
        ${textStyles};
    }
    
    h3 { 
        ${({$compactHeading}) => $compactHeading ? heading3Styles : heading2Styles}    
    }
    
    strong { font-weight: var(--fw-bold); }
    em { font-style: italic; }
    u { text-decoration: underline; }
    
    a {
        text-decoration: none;
        color: var(--color-green);
        font-weight: var(--fw-bold);
        
        &:hover {
            color: var(--link-hover);
        }
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;
