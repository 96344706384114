import styled from "styled-components";
import Input from '../Input/Input';
import RequiredText from '../RequiredText/RequiredText';

const StyledEmailField = styled(Input)``;

const EmailField = ({children, ...props}) => {
    return (
        <div>
            <StyledEmailField
                type="email"
                readOnly={props.readOnly}
                placeholder={props.placeholder}
                defaultValue={props.value}
                {...props.register(props.name, {
                    required: props.required,
                    onBlur: () => { if (props.fieldBlur) props.fieldBlur()}
                })}
                errors={props.errors}
            />
            {props.errors && <RequiredText>{props.errors.message || "Dit veld is verplicht"}</RequiredText>}
        </div>
    )
}

export default EmailField