import {useLoaderData} from "react-router-dom";
import styled from "styled-components";
import React, {useState} from "react";
import {HiMail, HiPhone} from "react-icons/hi";
import {useForm} from "react-hook-form";
import {Heading3, PageTitle} from '../../components/Heading/Heading';
import {RichTextHolder, Text} from '../../components/Text/Text';
import Button from '../../components/Button/Button';
import Question from '../../components/Question/Question';
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Page, Spinner, WrappedContainer} from '../../components/Utils/Utils';

const List = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 18px 0 48px;
`;

const ListItem = styled.li`
    svg {
        color: var(--color-green);
        vertical-align: middle;
        margin-right: 15px;
    }
    
    a {
        color: var(--color-green);
        font-size: var(--fs-text);
        word-break: break-all;
        font-weight: var(--fw-bold);
    }
    
    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
    
    &:hover {
        svg, a {
            color: var(--link-hover);
        }
    }
`;

const Form = styled.form`
    position: relative;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Overlay = styled.div`
    background: var(--color-background);
    opacity: .7;
    position: absolute;
    inset: 0;
`;

const SpinnerWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export async function contactLoader({request}) {
    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/contact-form`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page not found", { status: 404 });
    }

    const data = await res.json();

    return {data};
}

export default function Contact() {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY}>
            <ContactFormComponent/>
        </GoogleReCaptchaProvider>
    );
}

const ContactFormComponent = () => {
    const {data} = useLoaderData();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [status, setStatus] = useState('idle');

    const onSubmit = async values => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('submit');

        const body = JSON.stringify({
            "title": values.title,
            "email": values.email,
            "subject": values.subject,
            "message": values.message,
            "token": token,
        });

        setStatus('submitting');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/contact-form-submit`, {method: 'POST', body: body});
            if (!response.ok) {
                throw new Error();
            }
            setStatus('success');
        } catch (err) {
            setStatus('error');
        }
    };

    return (
        <Page>
            <WrappedContainer>
                <PageTitle>{data.title}</PageTitle>
                <RichTextHolder dangerouslySetInnerHTML={{__html: data.text}} $compactHeading />
                <List>
                    <ListItem>
                        <HiMail size={'1.25em'} />
                        <a href={`mailto:${data.email}`}>
                            {data.email}
                        </a>
                    </ListItem>
                    <ListItem>
                        <HiPhone size={'1.25em'} />
                        <a href={`tel:${data.phoneNumber}`}>
                            {data.phoneNumber}
                        </a>
                    </ListItem>
                </List>

                {status === 'success' &&
                    <>
                        <Heading3>Contactformulier verstuurd</Heading3>
                        <Text>Wij hebben je e-mail ontvangen en proberen zo snel mogelijk contact met je op te nemen.</Text>
                    </>
                }

                {status === 'error' &&
                    <>
                        <Heading3>Contactformulier niet verstuurd</Heading3>
                        <Text>Er ging iets fout aan onze kant. Probeer de pagina te verversen, of ga terug en probeer de handeling opnieuw.</Text>
                    </>
                }

                {(status === 'idle' || status === 'submitting') &&
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Question
                            key="title"
                            field={{id: "title", title: "Naam", type: "text", required: 1}}
                            placeholder="Typ je naam hier"
                            register={register}
                            errors={errors["title"]}
                            lessMarginOnMobile={true}
                        />

                        <Question
                            key="email"
                            field={{id: "email", title: "E-mailadres", type: "email", required: 1}}
                            placeholder="voorbeeld@emaildres.nl"
                            register={register}
                            errors={errors["email"]}
                            lessMarginOnMobile={true}
                        />

                        <Question
                            key="subject"
                            field={{id: "subject", title: "Onderwerp", type: "text", required: 0}}
                            placeholder="Typ het onderwerp hier"
                            register={register}
                            errors={errors["subject"]}
                            lessMarginOnMobile={true}
                        />

                        <Question
                            key="message"
                            field={{id: "message", title: "Bericht", type: "textarea", required: 1}}
                            placeholder="Typ hier je bericht"
                            register={register}
                            errors={errors["message"]}
                            lessMarginOnMobile={true}
                        />

                        <ButtonWrapper>
                            <Button as="button" loading={status === "submitting"} type="submit">Versturen</Button>
                        </ButtonWrapper>

                        {status === 'submitting' &&
                            <Overlay>
                                <SpinnerWrapper>
                                    <Spinner />
                                </SpinnerWrapper>
                            </Overlay>
                        }
                    </Form>
                }
            </WrappedContainer>
        </Page>
    )
}