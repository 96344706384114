import axios from "axios";
import Cookies from 'js-cookie';

async function get(object) {
    const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/${object}`);
    return data;
}

export async function authGet(object) {
    const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/${object}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get('kern_token')}`,
        }
    });
    return data;
}

export default get;