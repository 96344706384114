import {redirect, useLoaderData, useLocation, useNavigate} from 'react-router-dom';
import {Page, WrappedContainer} from '../../components/Utils/Utils';
import {Heading2} from '../../components/Heading/Heading';
import Button from '../../components/Button/Button';
import {HorizontalImageInner, HorizontalImageOuter} from '../../components/HorizontalImage/HorizontalImage';
import styled from 'styled-components';
import {RichTextHolder} from '../../components/Text/Text';
import React from 'react';
import Cookies from 'js-cookie';

const BackButton = styled.div`
    padding: 0 20px;
`;

const SolutionWrapper = styled.div`
    padding: 0 20px;
    
    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

const Solution = styled.article`
    box-shadow: var(--bs-block);
    border-radius: 20px;
    margin-top: 30px;
    
    @media screen and (max-width: 768px) {
        margin-top: 15px;
        border-radius: 0;
    }
`;

const StyledHorizontalImageOuter = styled(HorizontalImageOuter)`
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    overflow: hidden;
    
    @media screen and (max-width: 768px) {
        border-radius: 0;
    }
`;

const Inner = styled.div`
    background-color: var(--color-white);
    border-radius: 20px;
    padding: 40px;
    ${({$hasImage}) => $hasImage && 'margin-top: -20px;'};
    z-index: 1;
    position: relative;
    
    ${Heading2} {
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        border-radius: ${({$hasImage}) => $hasImage ? '10px 10px 0 0' : '0'};
        padding: 20px;
    }
`;

const PublicationDate = styled.p`
    font-size: var(--fs-data);
    color: var(--color-dark-green);
    line-height: 1;
    margin-left: 30px;

    @media screen and (max-width: 768px) {
        margin: 0 0 0 25px;
    }
`;

const ButtonWrapper = styled.div`
    margin-top: 40px;

    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`;

export async function solutionsDetailLoader({request, params}) {
    const hasAccessToken = Boolean(Cookies.get('kern_token'));
    if(!hasAccessToken) return redirect('/inloggen');

    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/solutions/${params.category}/details/${params.slug}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get('kern_token')}`
        },
        signal: request.signal
    });

    if(!res.ok) {
        // Redirect to login if Unauthorized
        if(res.status === 401) {
            Cookies.remove('kern_token');
            return redirect("/inloggen");
        }

        throw new Response("Page not found", { status: 404 });
    }

    const data = await res.json();

    return {data};
}

export default function SolutionsDetail() {
    const {data} = useLoaderData();
    const {state} = useLocation();
    const navigate = useNavigate();

    const isNews = data.type === "news";

    return (
        <Page $withBackground>
            <WrappedContainer $noPadding>
                <BackButton>
                    {state?.fromHome ? (
                        <Button as="button" onClick={() => navigate(-1)} previous $outline $secondary>Terug</Button>
                    ) : (
                        <Button to=".." relative="path" previous $outline $secondary>Terug</Button>
                    )}
                </BackButton>

                <SolutionWrapper>
                    <Solution>
                        {data.image &&
                            <StyledHorizontalImageOuter>
                                <HorizontalImageInner src={data.image} alt=""/>
                            </StyledHorizontalImageOuter>
                        }
                        <Inner $hasImage={Boolean(data.image)}>
                            <Heading2>{data.title}</Heading2>
                            {(isNews && data.publicationDate) && <PublicationDate>{data.publicationDate}</PublicationDate>}
                            {(data.fullText) && <RichTextHolder dangerouslySetInnerHTML={{__html: data.fullText}} $compactHeading />}
                            {(!isNews && data.buttonText && data.buttonURL) && <ButtonWrapper><Button to={data.buttonURL} target="_blank" rel="noreferrer">{data.buttonText}</Button></ButtonWrapper>}
                        </Inner>
                    </Solution>
                </SolutionWrapper>
            </WrappedContainer>
        </Page>
    );
}