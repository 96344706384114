import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import get, {authGet} from "../../requests/FetchApi";
import axios from "axios";
import { useFormData } from '../../contexts/FormContext';
import {useNavigate, useOutletContext} from "react-router-dom";
import RequiredText from "../../components/RequiredText/RequiredText";
import styled from 'styled-components';
import {Heading2} from '../../components/Heading/Heading';
import Button from '../../components/Button/Button';
import FormConfigField from '../../components/FormFields/FormConfigField';
import Label from '../../components/Label/Label';
import {Spinner, SpinnerWrapper} from '../../components/Utils/Utils';

const QuestionWrapper = styled.div`
    margin-bottom: 35px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default function FormConfig() {
    const {isLoggedIn, formReplyIDError, formReplyIDIsLoading} = useOutletContext();

    const {formReplyID, setFormReplyID, config, setFormConfig} = useFormData()
    const { register, unregister, watch, getValues, formState: { errors }, handleSubmit } = useForm();
    const watchSupplyChain = watch("supplyChain");
    const navigate = useNavigate();

    useEffect(()=> {
        if (formReplyID && (postData || patchData)) {
            navigate(`/quick-scan/2`);
        }
    // eslint-disable-next-line
    }, [formReplyID])

    const { data: filledFormConfigData, error: filledFormConfigError, isLoading: filledFormConfigIsLoading } = useQuery(['filledFormConfig', 'filledFormConfig'], () => authGet(`filled-form-config`), { enabled: isLoggedIn, cacheTime: 0, refetchOnWindowFocus: false })
    const { data: regionsData, error: regionsError, isLoading: regionsIsLoading } = useQuery(['regions', 'regions'], () => get('regions'), { refetchOnWindowFocus: false })
    const { data: supplyChainsData, error: supplyChainsError, isLoading: supplyChainsLoading } = useQuery(['supplychains', 'supplychains'], () => get('supplychains'), { refetchOnWindowFocus: false })

    // If a user is logged in, set the form config with the filledFormConfigData and load the form after the form config is set, to prevent unwanted flash
    const [isSettingFormConfig, setIsSettingFormConfig] = useState(isLoggedIn);
    useEffect(() => {
        if(filledFormConfigData) {
            setFormConfig(filledFormConfigData)
            setIsSettingFormConfig(false);
        }
    // eslint-disable-next-line
    }, [filledFormConfigData]);

    const postFormReply = async (body) => {
        const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/formreply`, body);
        return data;
    };

    async function patchFormReply(body) {
        const { data } = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/formreply/${formReplyID}`, body);
        return data;
    }

    const { mutate: post, isLoading: postLoading, data: postData } = useMutation(postFormReply, {
        onSuccess: data => {
            setFormReplyID(data.id);
        },
        onError: () => {
            return alert('Er is iets mis gegaan. Probeer het opnieuw.');
        },
    });

    const { mutate: patch, isLoading: patchLoading, data: patchData } = useMutation(patchFormReply, {
        onSuccess: data => {
            navigate(`/quick-scan/2`);
        },
        onError: () => {
            return alert('Er is iets mis gegaan. Probeer het opnieuw.');
        },
    });

    const isRegionAnswered = (regionID) => {
        return !!(config['region'] && config['region'].includes(regionID));
    }

    const isSupplyChainAnswered = (supplyChainID) => {
        return !!(config['supplyChain'] && config['supplyChain'].includes(supplyChainID));
    }

    const isSubLabelAnswered = (supplyChainID, subLabelID) => {
        return !!(config[supplyChainID] && config[supplyChainID].includes(subLabelID));
    }

    const fieldChange = () => {
        setFormConfig(getValues());
    }

    const format = (formData, val, includeSubLabels = true) => {
        return (
            {
                "id": val,
                ...(includeSubLabels && {
                    "subLabels": formData[val] ? (
                        Array.isArray(formData[val]) ? formData[val].map(i => ({"id": i,})) : [{"id": formData[val]}]
                    ) : [],
                })
            }
        )
    }

    const onSubmit = formData => {
        // Transform the data as the CMS expects a specific body
        const transformData = (input, type = 'supplyChain') => {
            let includeSubLabels = type === 'supplyChain';
            if (Array.isArray(input)) {
                return (
                    Object.entries(input).reduce((acc, [key, val]) => [...acc,
                        format(formData, val, includeSubLabels)
                    ], [])
                )
            }
            return [format(formData, input, includeSubLabels)];
        }

        const transformedSupplyChain = transformData(formData['supplyChain'], "supplyChain");
        const transformedRegion = transformData(formData['region'], "region");

        const baseObj = {
            "supplychain": transformedSupplyChain,
            "region": transformedRegion
        };

        if(!formReplyID){
            post(baseObj);
        } else {
            patch(baseObj);
        }
    };

    if(watchSupplyChain) {
        const values = getValues();

        supplyChainsData.forEach((supplyChain) => {
            if(!values.supplyChain.includes(supplyChain.id) && supplyChain.id in values){
                unregister(supplyChain.id);
            }
        })
    }

    if (supplyChainsError || regionsError || (isLoggedIn && formReplyIDError) || (isLoggedIn && filledFormConfigError)) return alert('Er is iets mis gegaan. Probeer het opnieuw.');

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {(supplyChainsLoading || regionsIsLoading || (isLoggedIn && formReplyIDIsLoading)) || (isLoggedIn && filledFormConfigIsLoading) || isSettingFormConfig ? (
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            ) : (
                <>
                    <Heading2>1. Regio's en goederenstromen</Heading2>

                    <QuestionWrapper>
                        <Label>Van welke stad of regio wil je informatie?</Label>
                        {regionsData.items.map(region => (
                            <div key={region.id}>
                                <FormConfigField
                                    name={'region'}
                                    value={region.id}
                                    defaultChecked={isRegionAnswered(region.id)}
                                    register={register}
                                    required={true}
                                    fieldChange={fieldChange}
                                >
                                    {region.title}
                                </FormConfigField>
                            </div>
                        ))}
                        {errors.region && <RequiredText>Dit veld is verplicht</RequiredText>}
                    </QuestionWrapper>

                    <QuestionWrapper>
                        <Label>Welke type goederenstromen zijn van toepassing op uw organisatie?</Label>
                        {supplyChainsData.map((supplyChain) => {
                            return (
                                <div key={supplyChain.id}>
                                    <FormConfigField
                                        name={'supplyChain'}
                                        value={supplyChain.id}
                                        defaultChecked={isSupplyChainAnswered(supplyChain.id)}
                                        register={register}
                                        required={true}
                                        fieldChange={fieldChange}
                                    >
                                        {supplyChain.title}
                                    </FormConfigField>
                                </div>
                            );
                        })}
                        {errors.supplyChain && <RequiredText>Dit veld is verplicht</RequiredText>}
                    </QuestionWrapper>

                    {supplyChainsData.map((supplyChain) => {
                        if (supplyChain.subLabels.length && (config?.supplyChain && config?.supplyChain?.includes(supplyChain.id))) {
                            return (
                                <QuestionWrapper key={supplyChain.id}>
                                    <Label>{supplyChain.questionTitle}</Label>
                                    {supplyChain.subLabels.map((subLabel) => {
                                        return (
                                            <div key={subLabel.id}>
                                                <FormConfigField
                                                    name={supplyChain.id}
                                                    value={subLabel.id}
                                                    defaultChecked={isSubLabelAnswered(supplyChain.id, subLabel.id)}
                                                    register={register}
                                                    required={true}
                                                    fieldChange={fieldChange}
                                                >
                                                    {subLabel.title}
                                                </FormConfigField>
                                            </div>
                                        )
                                    })}
                                    {errors[supplyChain.id] && <RequiredText>Dit veld is verplicht</RequiredText>}
                                </QuestionWrapper>
                            )
                        }
                        return null;
                    })}

                    <ButtonWrapper>
                        <Button as="button" type="submit" loading={patchLoading || postLoading} disabled={patchLoading || postLoading}>Volgende</Button>
                    </ButtonWrapper>
                </>
            )}
        </form>
    );
}