import {PageTitle} from '../../components/Heading/Heading';
import {Page, WrappedContainer} from '../../components/Utils/Utils';
import CardContainer from '../../components/CardContainer/CardContainer';
import {RichTextHolder} from '../../components/Text/Text';
import {useLoaderData} from 'react-router-dom';

export async function cookiePolicyLoader({request}) {
    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/privacypolicy`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page not found", { status: 404 });
    }

    const data = await res.json();

    return {data};
}

export default function CookiePolicy() {
    const {data} = useLoaderData();

    return (
        <Page>
            <WrappedContainer>
                <PageTitle>{data.cookieTitle}</PageTitle>
            </WrappedContainer>

            <CardContainer>
                <RichTextHolder dangerouslySetInnerHTML={{__html: data.cookieText}} />
            </CardContainer>
        </Page>
    );
}