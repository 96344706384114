import {Heading2, Heading3, PageTitle} from '../Heading/Heading';
import Button from '../Button/Button';
import {ConditionalWrap, Container} from '../Utils/Utils';
import styled from 'styled-components';
import {MdLocationOn} from 'react-icons/md';
import {RichTextHolder, Text} from '../Text/Text';
import {Link} from 'react-router-dom';
import {HiOutlineArrowRight} from 'react-icons/hi';

const Regions = styled(Heading3)`
    margin-top: 15px;
`;

const RegionIcon = styled(MdLocationOn)`
    color: var(--color-green);
    vertical-align: top;
    margin-right: 5px;
`;

const StyledText = styled(RichTextHolder)`
    max-width: 630px;
    margin: 20px 0 30px 0;
    
    @media screen and (max-width: 768px) {
        margin: 0 0 20px 0;
    }
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 40px;
    
    @media screen and (max-width: 580px) {
        flex-direction: column;
    }
`;

const CompanyInfo = styled.div``;

const Emission = styled(Link)`
    color: inherit;
    text-decoration: inherit;
    background-color: var(--color-dark-green);
    align-self: center;
    border-radius: 10px;
    box-shadow: var(--bs-block);
    padding: 28px;
    flex-basis: 400px;

    @media screen and (max-width: 580px) {
        align-self: flex-start;
        flex-basis: unset;
        width: 100%;
    }
`;

const StyledHeading2 = styled(Heading2)`
    font-weight: var(--fw-semi-bold);
    color: var(--color-white);

    sub { vertical-align: bottom; }
`;

const EmissionLabel = styled(Text)`
    margin: 0 0 8px;

    sub { vertical-align: bottom; }
`;

const EmissionFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
    flex-wrap: wrap;
`;

const DisplayEmission = styled.h3`
    position: relative;
    font-family: var(--font-mulish);
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-h2);
    line-height: 1;
    color: var(--color-white);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;

    @media screen and (max-width: 580px) {  
        max-width: unset;
    }
`;

const ReadMore = styled.span`
    color: var(--color-white);
    font-family: var(--font-mulish);
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    text-decoration: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
`;

const ArrowRight = styled(HiOutlineArrowRight)`
    color: var(--color-green);
    margin-left: 15px;
    transition: transform .2s ease;
    
    ${Emission}:hover & {
        transform: translateX(4px);
    }
`;

export const Company = ({regions, compact, introText, emission, children}) => {

    return (
        <Container>
            <ConditionalWrap condition={Boolean(emission)} wrap={children => <Flex>{children}</Flex>}>
                <CompanyInfo>
                    <PageTitle>{children}</PageTitle>
                    {regions?.length > 0 &&
                        <Regions>
                            <RegionIcon size={'1.25em'} />
                            {Array.isArray(regions) ? (
                                regions?.map((item, index, array) => (
                                    <span key={item.id}>
                                        {item.title}{array.length - 1 !== index && ', '}
                                    </span>
                                ))
                            ) : (
                                <span >
                                    {regions}
                                </span>
                            )}

                        </Regions>
                    }
                    {!compact &&
                        <>
                            <StyledText dangerouslySetInnerHTML={{__html: introText}} />
                            <Button to="/quick-scan" $outline>Wijzig mijn Quick Scan</Button>
                        </>
                    }
                </CompanyInfo>
                {Boolean(emission) &&
                    <Emission to="/mijn-co2-footprint">
                        <StyledHeading2>CO<sub>2</sub> footprint</StyledHeading2>
                        <EmissionLabel $white>Totale kg CO<sub>2</sub> emissie per week</EmissionLabel>
                        <EmissionFlex>
                            <DisplayEmission>{emission.emissionTotal?.toLocaleString('nl', {maximumFractionDigits: 2})}</DisplayEmission>
                            <ReadMore>Bekijk meer <ArrowRight size={'1.5em'}/></ReadMore>
                        </EmissionFlex>
                    </Emission>
                }
            </ConditionalWrap>
        </Container>
    );
}