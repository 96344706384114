import styled from 'styled-components';
import Input from '../Input/Input';
import RequiredText from '../RequiredText/RequiredText';

const StyledNumberField = styled(Input)``;

const NumberField = ({children, ...props}) => {
    return (
        <>
            <StyledNumberField
                type="number"
                defaultValue={props.value}
                {...props.register(props.name, {
                    required: props.required,
                    onBlur: (e) => {props.fieldBlur()},
                })}
                errors={props.errors}
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            />
            {props.errors && <RequiredText>Dit veld is verplicht</RequiredText>}
        </>
    )
}

export default NumberField