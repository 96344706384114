import {Outlet, useRouteLoaderData} from 'react-router-dom';
import styled from 'styled-components';
import {PageTitle} from '../../components/Heading/Heading';
import {Page, WrappedContainer} from '../../components/Utils/Utils';
import CardContainer from '../../components/CardContainer/CardContainer';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import {useQuery} from '@tanstack/react-query';
import {authGet} from '../../requests/FetchApi';
import {useEffect, useState} from 'react';
import {useFormData} from '../../contexts/FormContext';

const StyledFormSteps = styled(Page)`
    min-height: calc(100vh - 250px);
`;

export default function FormSteps() {
    const {checkLoginData} = useRouteLoaderData("root");
    const isLoggedIn = Boolean(checkLoginData?.isLoggedIn);

    const {setValues, setFormReplyID} = useFormData();

    // Fetch formReplyID and filledForm if user is logged in (user can change existing quick scan)
    const { data: formReplyIDData, error: formReplyIDError, isLoading: formReplyIDIsLoading } = useQuery(['formReplyID', 'formReplyID'], () => authGet('formreplyid'), { enabled: isLoggedIn, cacheTime: 0, refetchOnWindowFocus: false })
    const { data: filledFormData, error: filledFormError, isLoading: filledFormIsLoading } = useQuery(['filledForm', 'filledForm'], () => authGet('filled-form'), { enabled: isLoggedIn, cacheTime: 0, refetchOnWindowFocus: false })

    // Setting fetched formReplyID in context
    useEffect(() => {
        if(isLoggedIn && formReplyIDData?.id) {
            setFormReplyID(formReplyIDData?.id);
        }
        // eslint-disable-next-line
    }, [formReplyIDData]);

    // Setting fetched formData in context
    const [isSettingFormData, setIsSettingFormData] = useState(isLoggedIn);
    useEffect(() => {
        if(isLoggedIn && filledFormData) {
            setValues(filledFormData);
            setIsSettingFormData(false);
        }
        // eslint-disable-next-line
    }, [filledFormData]);

    return (
        <StyledFormSteps $withBackground $withBackgroundOnMobile>
            <ScrollToTop />

            <WrappedContainer>
                <PageTitle>Jouw Quick Scan</PageTitle>
            </WrappedContainer>

            <CardContainer>
                <Outlet context={{
                    isLoggedIn,
                    formReplyIDData, formReplyIDError, formReplyIDIsLoading,
                    filledFormData, filledFormError, filledFormIsLoading, isSettingFormData
                }} />
            </CardContainer>
        </StyledFormSteps>
    );
}