import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }
    
    :root {
        /* Base colors */
        --color-background: #FCFCFC;
        --color-green: #709F2D;
        --color-green-60: #628C27;
        --color-green-70: #5B8223;
        --color-dark-green: #355B59;
        --color-white: #fff;
        --color-grey: #424242;
        --color-drag-stroke: #E6E6E6;
        --color-drag-fill: #F5F7F7;
        --color-supplier-field-background: #EFEFEF;
        --color-supplier-field-accent: rgba(53, 91, 89, .05);
        --color-supplier-field-dropdown-border: #E5E5E5;
        
        /* RGB values */
        --rgb-green: 112, 159, 45;
        --rgb-dark-green: 53, 91, 89;
        --rgb-color-grey: 66, 66, 66;
        
        /* Fonts */
        --font-mulish: 'Mulish', sans-serif;
        --font-source-sans-pro: 'Source Sans Pro', sans-serif;
        
        /* Font sizes */
        --fs-h1: 48px;
        --fs-h2: 36px;
        --fs-h3: 20px;
        --fs-label: 18px;
        --fs-label-small: 16px;
        --fs-text: 18px;
        --fs-button: 16px;
        --fs-pagination: 24px;
        --fs-nav: 20px;
        --fs-copyright: 14px;
        --fs-footer-heading: 19px;
        --fs-data: 14px;
        --fs-multi-select-label: 14px;
        
        @media screen and (max-width: 875px) {
            --fs-nav: 16px;
        }
        
        @media screen and (max-width: 768px) {
            --fs-h1: 21px;
            --fs-h2: 18px;
            --fs-h3: 16px;
            --fs-label: 16px;
            --fs-text: 16px;
            --fs-footer-heading: 18px;
            --fs-button: 14px;
            --fs-data: 12px;
        }
        
        @media screen and (max-width: 475px) {
            --fs-pagination: 16px;
        }

        @media screen and (max-width: 330px) {
            --fs-pagination: 12px;
        }
        
        /* Font weights */
        --fw-extra-light: 200;
        --fw-light: 300;
        --fw-regular: 400;
        --fw-semi-bold: 600;
        --fw-bold: 700;
        --fw-black: 900;
        
        /* Box shadows */
        --bs-zone: 1px 1px 8px rgba(0, 0, 0, 0.25);
        --bs-block: 1px 1px 12px rgba(0, 0, 0, 0.25);
        --bs-menu: 0px -1px 10px rgba(0, 0, 0, 0.25);
        --bs-text: 1px 1px 2px rgba(0, 0, 0, 0.5);
        --bs-nav: 0px 4px 10px rgba(0, 0, 0, 0.25);
        --bs-form: 0px 0px 10px rgba(0, 0, 0, 0.25);
        --bs-drag: 1px 1px 5px rgba(0, 0, 0, 0.15);
        
        --link-hover: #87C036;

        /* Buttons */
        --back-button-pressed: #F5F7F7;
        --next-button-pressed: #274341;
        
        /* Question */
        --color-required: #F04848;
        --color-asterisk: #CA1D1D;
        --color-border: #E6E6E6;
    }
    
    html, body {
        margin: 0;
        padding: 0;
        background-color: var(--color-background);
        font-family: var(--font-source-sans-pro);
    }

    /* NProgress navigation loader bar */
    #nprogress {
        pointer-events: none;
    }

    #nprogress .bar {
        background: var(--color-green);
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
    }

    /* Prevent scrolling when mobile menu is open */
    .no-scroll {
        @media screen and (max-width: 625px) {
            overflow: hidden;
            touch-action: none;
        }
    }
`;

export default GlobalStyle;