import styled from "styled-components";
import check from '../../assets/icons/check.svg'

const Wrapper = styled.div`
    margin-top: 12px;
    display: flex;
`

const CheckBoxInput = styled.input`
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 5px;
    vertical-align: middle;
    border: 1.5px solid #DCDCDC;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0 13px 0 0;

    &:checked {
        border: 0;
        background-image: url(${check});
        background-color: var(--color-green);
        background-size: 65% 65%;
    }
`

const CheckBoxLabel = styled.label`
    vertical-align: middle;
    font-size: var(--fs-text);
    color: var(--color-grey);
    font-weight: var(--fw-regular);
`

const FormConfigField = ({children, ...props}) => {
    return (
        <Wrapper>
            <CheckBoxInput type='checkbox' id={props.value} value={props.value} defaultChecked={props.defaultChecked}
                {...props.register(props.name, {
                   required: props.required,
                   onChange: (e) => {props.fieldChange()},
                })}
            />
            <CheckBoxLabel htmlFor={props.value}>{children}</CheckBoxLabel>
        </Wrapper>
    )
}

export default FormConfigField