import {isRouteErrorResponse, useRouteError} from "react-router-dom";
import {Heading2, PageTitle} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {Page, WrappedContainer} from '../../components/Utils/Utils';
import CardContainer from '../../components/CardContainer/CardContainer';

export default function ErrorPage() {
    const error = useRouteError();

    let title;
    let subtitle;
    let text;

    if(isRouteErrorResponse(error)) {
        switch(error.status) {
            case 400: {
                title = "Er is iets fout gegaan";
                subtitle = "Foutmelding";
                text = "Probeer de actie opnieuw.";
                break;
            }
            case 401: {
                title = "Er is iets fout gegaan";
                subtitle = "Foutmelding";
                text = "Het lijkt erop dat je geen toegang hebt tot deze actie.";
                break;
            }
            default: {
                title = "404 Pagina niet gevonden";
                subtitle = "Pagina niet gevonden";
                text = "Het lijkt erop dat deze pagina niet bestaat.";
            }
        }
    }

    return (
        <Page>
            <WrappedContainer>
                <PageTitle>{title}</PageTitle>
            </WrappedContainer>

            <CardContainer>
                <Heading2>{subtitle}</Heading2>
                <Text>{text}</Text>
            </CardContainer>
        </Page>
    );
}