import styled from 'styled-components';

const StyledParagraph = styled.p`
    font-style: italic;
    font-size: 14px;
    line-height: 25px;
    color: var(--color-required);
    margin: 0;
`;

const RequiredText = ({className, children}) => {
    return (
            <StyledParagraph className={className}>
                {children}
            </StyledParagraph>
    )
}

export default RequiredText