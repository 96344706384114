import styled from 'styled-components';

const Input = styled.input`
    width: 100%;
    height: 50px;
    padding: 12.5px;
    
    border: solid 1.5px ${props => props?.errors ? "var(--color-required)" : "var(--color-border)"};
    border-radius: 10px;

    color: var(--color-grey);
    
    font-size: var(--fs-text);
    font-weight: var(--fw-regular);
    font-family:inherit;
    margin-top: 10px;

    background: var(--color-white);

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none !important;

    &:not(:read-only):focus {
        border: solid 1.5px var(--color-green)
    }
    
    &:read-only {
        opacity: .5;
    }
`;

export default Input