import React, {useCallback, useEffect, useState} from 'react';
import {useParams, useNavigate, useBeforeUnload, useOutletContext} from 'react-router-dom';
import styled from 'styled-components';
import {useFormData} from '../../contexts/FormContext';
import {useForm} from "react-hook-form";
import {useMutation, useQuery} from "@tanstack/react-query";
import axios from "axios";
import {Heading2} from '../../components/Heading/Heading';
import Button from '../../components/Button/Button';
import Pagination from '../../components/Pagination/Pagination';
import Question from '../../components/Question/Question';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import {Spinner, SpinnerWrapper} from '../../components/Utils/Utils';
import get, {authGet} from '../../requests/FetchApi';
import Cookies from 'js-cookie';

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
`;

export default function FormStep() {
    const {isLoggedIn, filledFormError, filledFormIsLoading, isSettingFormData} = useOutletContext();

    // Before user unloads the page, give a warning
    useBeforeUnload(
        useCallback((e) => {
            e.preventDefault();
            e.returnValue = "";
        }, [])
    );

    const formReply = useFormData();

    const { status, data, isFetching } = useQuery(["formSteps"], () => isLoggedIn ? authGet(`form/${formReply.formReplyID}`) : get(`form/${formReply.formReplyID}`), { refetchOnWindowFocus: false })

    const { getValues, setValue, register, unregister, handleSubmit, formState: { errors } } = useForm({
        defaultValues: formReply.values
    });

    // State to track the questions from the current step
    const [currentStepData, setCurrentStepData] = useState();

    // Hook that runs when the step changes (i.e. on next/prev button click)
    useEffect(() => {
        if(data) {
            const upcomingStepData = data?.steps[formReply.step];
            setCurrentStepData(upcomingStepData);

            formReply.setValues((currentValues) => {
                const newValues = upcomingStepData?.question.reduce((obj, item) => {
                    obj[item.id] = "";

                    return obj;
                }, {});

                return Object.assign({}, newValues, currentValues);
            });
        }
    // eslint-disable-next-line
    }, [formReply.step, data]);

    // Get the values onBlur of a question and update context
    const fieldBlur = () => {
        formReply.setFormValues(getValues());
    }

    // Navigate to previous or next step on button click
    const navigate = useNavigate();

    const navigateSteps = (direction) => () => {
        // Prevent step to go negative
        if(direction === 'prev' && formReply.step - 1 === -1) {
            formReply.setStep(0);
            formReply.setVirtualStep(2);
            return;
        }

        // Set step in context
        formReply.setStep(direction === "next" ? formReply.step + 1 : formReply.step - 1);
        formReply.setVirtualStep(direction === "next" ? formReply.virtualStep + 1 : formReply.virtualStep - 1);

        // For the next button, navigate to the next step
        if(direction === 'next') navigate(`/quick-scan/${formReply.virtualStep + 1}`);
    };

    const prevStep = navigateSteps("prev");
    const nextStep = navigateSteps("next");

    // Routing system to support browser buttons
    let { slug } = useParams();

    useEffect(() => {
        if(parseInt(slug) >= 1 && parseInt(slug) <= data?.steps?.length + 1) {
            // If slug is smaller than step (Previous button
            if (parseInt(slug) < formReply.virtualStep) {
                formReply.setVirtualStep(parseInt(slug))
                formReply.setStep(parseInt(slug) - 2)
            }

            // If slug is bigger than step AND less than/equal to step + 1 (Next button)
            if (parseInt(slug) > formReply.virtualStep && parseInt(slug) <= formReply.virtualStep + 1) {
                formReply.setVirtualStep(parseInt(slug))
                formReply.setStep(parseInt(slug) - 2)
            }
        }
    }, [slug, formReply, data?.steps?.length])

    // Put mutation for PUT request with answers
    async function putFormReply(body) {
        let headers = {
            'Accept' : 'application/json',
            ...(isLoggedIn && {Authorization: `Bearer ${Cookies.get('kern_token')}`})
        }
        const { data } = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/formreply/${formReply.formReplyID}/answers`, body, { headers: headers });
        return data;
    }

    const { mutate: put, isLoading: putLoading } = useMutation(putFormReply, {
        onSuccess: data => {
            navigate('/quick-scan/success')
        },
        onError: (axiosError) => {
            return alert(axiosError?.response?.data?.message ? axiosError.response.data.message : 'Er is iets mis gegaan. Probeer het opnieuw.');
        },
    });

    // Handle onSubmit of each form (= each step)
    const onSubmit = formData => {
        // If it's the last step...
        if(formReply.step === data?.steps?.length - 1){

            // Make an array of all questions
            let questionsArray = []

            data.steps.map((item) => {
                return item.question.map((question) => {
                    return questionsArray.push(question);
                })
            })

            // Reduce the questionsArray to an object with the formData values
            const questionsObject = questionsArray.reduce((obj, item) => {
                obj[item.id] = formData[item.id];

                return obj;
            }, {});

            // Filter 'other-' prefix keys and return an object
            const otherPrefixObject = Object.fromEntries(Object.entries(formData).filter(([key, value]) => key.startsWith('other-')))

            // Filter 'different-' prefix keys and return an object
            const differentPrefixObject = Object.fromEntries(Object.entries(formData).filter(([key, value]) => key.startsWith('different-')))

            // Merge the objects using a spread operator
            const correctFormData = {...questionsObject, ...otherPrefixObject};

            // Transform the data as the CMS expects a specific body
            const transformData = (input) => {
                return (
                    Object.entries(input).reduce((acc, [key, val]) => [...acc,
                        {
                            "questionId": key,
                            "valueText": Array.isArray(val) || val === false ? "" : val,
                            "valueOption": (Array.isArray(val) && !val.includes(null) && !val.includes('')) ? val.map(i => (
                                {
                                    "id": i !== null ? i : "",
                                    "valueText": input[`other-${i}`] ? input[`other-${i}`] : ""
                                }
                            )) : [],
                            "isDifferent": differentPrefixObject[`different-${key}`] ? differentPrefixObject[`different-${key}`] : false,
                        }
                    ], [])
                )
            }


            // const transformedOutput = transformData(formReply.values)
            const transformedOutput = transformData(correctFormData);

            // Remove redundant entries with 'other-' prefix from context values
            const removeRedundantOptions = (input) => {
                return (
                    input.filter((item) => {
                        return item.questionId.indexOf("other-") !== 0;
                    })
                )
            }

            const output = removeRedundantOptions(transformedOutput)

            // Create the Base Object to combine the FormReplyID with the formatted output
            const baseObj = {...(formReply.formReplyID && {"id": formReply.formReplyID}), "answers": output}

            // Call PUT request with baseObject in JSON
            put(JSON.stringify(baseObj, null, 2));

            return;
        }

        // Else go to the next step
        nextStep();
    };

    if(status === "error" || (isLoggedIn && filledFormError)) return alert('Er is iets mis gegaan. Probeer het opnieuw.');

    return (
        <>
            {(status === 'loading' || isFetching || (isLoggedIn && filledFormIsLoading) || isSettingFormData) ? (
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            ) : (
                <>
                    <ProgressBar activeStep={formReply.step + 1} maxSteps={data?.steps?.length + 1}/>

                    <Heading2>{formReply.virtualStep}. {currentStepData?.title}</Heading2>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        {currentStepData?.question.map((field) => (
                            <Question
                                key={field.id}
                                field={field}
                                value={formReply.values[field.id]}
                                setValue={setValue}
                                register={register}
                                unregister={unregister}
                                fieldBlur={fieldBlur}
                                errors={errors[field.id]}
                            />
                        ))}

                        <ButtonWrapper>
                            <Button previous to={formReply.step !== 0 ? `/quick-scan/${formReply.virtualStep - 1}` : '/quick-scan/1'} onClick={prevStep}>Vorige</Button>
                            <Pagination>{formReply.virtualStep} / {data?.steps?.length + 1}</Pagination>
                            <Button as="button" type="submit" loading={putLoading} disabled={putLoading}>{formReply.step === data?.steps?.length - 1 ? 'Versturen' : 'Volgende'}</Button>
                        </ButtonWrapper>
                    </form>
                </>
            )}
        </>
    );
}