import Cookies from 'js-cookie';
import React from 'react';
import {redirect} from 'react-router-dom';

export async function logoutAction({request}) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/logout`, {
        signal: request.signal,
        method: 'POST',
        headers: {
            Authorization: `Bearer ${Cookies.get('kern_token')}`,
        }
    });

    if(!response.ok) {
        throw new Response("Bad request", { status: 400 });
    }

    Cookies.remove('kern_token');

    return redirect('/inloggen');
}

export default function Logout() {

    return (
        <></>
    )
}