import {createBrowserRouter, Navigate, Outlet, RouterProvider, useOutletContext, useRouteLoaderData} from "react-router-dom";
import FormProvider, {useFormData} from "./contexts/FormContext";
import Root, {RootError, rootLoader} from "./routes/Root/Root";
import Home, {homeLoader} from "./routes/Home/Home";
import PrivacyPolicy, {privacyPolicyLoader} from "./routes/PrivacyPolicy/PrivacyPolicy";
import Contact, {contactLoader} from "./routes/Contact/Contact";
import {HelmetProvider} from "react-helmet-async";
import FormSteps from "./routes/FormSteps/FormSteps";
import FormConfig from "./routes/FormConfig/FormConfig";
import FormStep from "./routes/FormStep/FormStep";
import FormSuccess from "./routes/FormSuccess/FormSuccess";
import NoMatch from "./routes/NoMatch/NoMatch";
import SolutionsOverview, {solutionsOverviewLoader} from "./routes/SolutionsOverview/SolutionsOverview";
import SolutionsDetail, {solutionsDetailLoader} from "./routes/SolutionsDetail/SolutionsDetail";
import Login, {loginAction} from "./routes/Login/Login";
import ResetPassword, {resetPasswordAction} from "./routes/ResetPassword/ResetPassword";
import ChangePassword, {changePasswordAction} from "./routes/ChangePassword/ChangePassword";
import Logout, {logoutAction} from "./routes/Logout/Logout";
import CookiePolicy, {cookiePolicyLoader} from "./routes/CookiePolicy/CookiePolicy";
import About, {aboutLoader} from "./routes/About/About";
import MyFootprint, {myFootprintLoader} from "./routes/MyFootprint/MyFootprint";

function App() {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            errorElement: <RootError />,
            loader: rootLoader,
            id: "root",
            children: [
                {
                    index: true,
                    element: <Home />,
                    loader: homeLoader,
                    errorElement: <NoMatch />,
                },
                {
                    path: '/:slug',
                    element: <SolutionsOverview />,
                    loader: solutionsOverviewLoader,
                    errorElement: <NoMatch />,
                },
                {
                    path: '/:category/:slug',
                    element: <SolutionsDetail />,
                    loader: solutionsDetailLoader,
                    errorElement: <NoMatch />,
                },
                {
                    path: '/mijn-co2-footprint',
                    element: <MyFootprint />,
                    loader: myFootprintLoader,
                    errorElement: <NoMatch />,
                },
                {
                    element: <RequireGuest />,
                    children: [
                        {
                            path: '/inloggen',
                            element: <Login />,
                            action: loginAction,
                            errorElement: <NoMatch />,
                        },
                    ],
                },
                {
                    path: '/uitloggen',
                    element: <Logout />,
                    action: logoutAction,
                    errorElement: <NoMatch />,
                },
                {
                    path: '/wachtwoord-resetten',
                    element: <ResetPassword />,
                    action: resetPasswordAction,
                    errorElement: <NoMatch />,
                },
                {
                    path: '/wachtwoord-veranderen',
                    element: <ChangePassword />,
                    action: changePasswordAction,
                    errorElement: <NoMatch />,
                },
                {
                    path: '/quick-scan',
                    element: <FormSteps />,
                    errorElement: <NoMatch />,
                    children: [
                        {
                            index: true,
                            element: <FormConfig />,
                            errorElement: <NoMatch />,
                        },
                        {
                            path: '/quick-scan/1',
                            element: <FormConfig />,
                            errorElement: <NoMatch />,
                        },
                        {
                            element: <RequireFormReplyID />,
                            children: [
                                {
                                    path: '/quick-scan/:slug',
                                    element: <FormStep />,
                                    errorElement: <NoMatch />,
                                },
                            ],
                        },
                        {
                            path: '/quick-scan/success',
                            element: <FormSuccess />,
                            errorElement: <NoMatch />,
                        }
                    ],
                },
                {
                    path: '/over',
                    element: <About />,
                    loader: aboutLoader,
                    errorElement: <NoMatch />,
                },
                {
                    path: '/contact',
                    element: <Contact />,
                    loader: contactLoader,
                    errorElement: <NoMatch />,
                },
                {
                    path: '/privacy-policy',
                    element: <PrivacyPolicy />,
                    loader: privacyPolicyLoader,
                    errorElement: <NoMatch />,
                },
                {
                    path: '/cookiebeleid',
                    element: <CookiePolicy />,
                    loader: cookiePolicyLoader,
                    errorElement: <NoMatch />,
                },
                {
                    path: "*",
                    element: <NoMatch />,
                }
            ],
        },
    ]);

    return (
        <FormProvider>
            <HelmetProvider>
                <RouterProvider router={router} />
            </HelmetProvider>
        </FormProvider>
    )
}

// Route protector for FormSteps if there is no FormReplyID
function RequireFormReplyID() {
    const { formReplyID } = useFormData();
    const {isLoggedIn, filledFormData, filledFormError, filledFormIsLoading, isSettingFormData} = useOutletContext();

    if(!formReplyID) {
        return <Navigate to='/quick-scan/1' replace={true} />
    }

    return <Outlet context={{isLoggedIn, filledFormData, filledFormError, filledFormIsLoading, isSettingFormData}} />;
}

// Route protector for Login if user is logged in
function RequireGuest() {
    const {checkLoginData} = useRouteLoaderData("root");

    if(checkLoginData?.isLoggedIn) {
        return <Navigate to='/' replace={true} />
    }

    return <Outlet />
}

export default App;